import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'ParseHtml'
})

export class ParseHtmlPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer){}

    transform(text: string) {
        return this._sanitizer.bypassSecurityTrustHtml(this.parseUrl(text));
    }

    private parseUrl(text: string) {

        const regexpHash = /#[^ !@#$%^&*(),.?":{}|<>]+/gi;
        if (text.match(regexpHash)) {
            text = text.replace(regexpHash, function replacer(str) {
                const htmlToReturn = "<div class='hashtag' tappable title='" + str + "' alt='hashtag'>" + str + "</div>";
                return htmlToReturn;
            });
        }

        const regexpUrl =/(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        if (text.match(regexpUrl)) {
            text = text.replace(regexpUrl, function replacer(str) {
                const htmlToReturn = "<div class='hashtag' tappable title='" + str + "' alt='enlace'>" + str.substring(0,25) + "..</div>";
                return htmlToReturn;
            });
        }

        return text;
    }  

    


}