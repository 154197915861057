import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
})

export class PlaceholderComponent implements OnInit {

	Repeticiones : number = 20;
	@Input() Tipo: any;

	constructor() { 
	
	}

	ngOnInit() {}

}
