<div *ngIf="Tipo=='Muro'" class="BloqueMuro">
	<div style="height: 160px;" class="ion-text-center">
		<ion-avatar style="margin-left:30%">
			<ion-skeleton-text animated style="width:140px; height:140px; "></ion-skeleton-text>
		</ion-avatar>
	</div>
	
</div>
<div *ngIf="Tipo=='Chat'">
	<ion-item style="border-bottom: 1px solid #f0f0f0;" lines="none">
		<ion-avatar slot="start">
			<ion-skeleton-text animated></ion-skeleton-text>
		</ion-avatar>
		<ion-label>
			<h3><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></h3>
			<p><ion-skeleton-text animated style="width: 80%"></ion-skeleton-text></p>
			<p><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
</div>

<div *ngIf="Tipo=='Seguido'" class="BloqueSeguido">
	<ion-item>
		<ion-avatar slot="start">
			<ion-skeleton-text animated></ion-skeleton-text>
		</ion-avatar>
		<ion-label>
			<h3><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></h3>
			<p><ion-skeleton-text animated style="width: 80%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
	<!--
	<ion-item>
		<ion-label>
			<p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
			<p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
	-->
</div>

<div *ngIf="Tipo=='Comentario'" >
	<ion-item style="border-bottom: 1px solid #f0f0f0;">
		<ion-avatar slot="start">
			<ion-skeleton-text animated></ion-skeleton-text>
		</ion-avatar>
		<ion-label>
			<h3><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></h3>
			<p><ion-skeleton-text animated style="width: 80%"></ion-skeleton-text></p>
			<p><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
	<ion-item>
		<ion-label>
			<p><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
			<p><ion-skeleton-text animated style="width: 70%"></ion-skeleton-text></p>
			<p><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
</div>

<div *ngIf="Tipo=='Sugerido'" class="Bloque" style="width:44%; text-align: center; float:left; margin:10px">
	<div>
		<ion-avatar slot="start">
			<ion-skeleton-text animated></ion-skeleton-text>
		</ion-avatar>
		<div style="min-height: 11vh">
			<ion-skeleton-text animated style="width:100%"></ion-skeleton-text>
			<ion-skeleton-text animated style="width:100%"></ion-skeleton-text>
		</div>
		<ion-skeleton-text animated style="width:100%"></ion-skeleton-text>
	</div>
</div>

<div *ngIf="Tipo=='Post'" class="BloquePost">
	<ion-item >
		<ion-avatar slot="start">
			<ion-skeleton-text animated></ion-skeleton-text>
		</ion-avatar>
		<ion-label>
			<h3><ion-skeleton-text animated style="width: 50%"></ion-skeleton-text></h3>
			<p><ion-skeleton-text animated style="width: 80%"></ion-skeleton-text></p>
			<p><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
	<ion-item>
		<ion-label>
			<p><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
			<p><ion-skeleton-text animated style="width: 70%"></ion-skeleton-text></p>
			<p><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
	<ion-item>
		<ion-label>
			<p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
			<p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
			<p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
			<p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
		</ion-label>
	</ion-item>
</div>

<div *ngIf="Tipo=='Anuncio'" class="BloqueAnuncio">
    <ion-skeleton-text animated style="width:100%; height:140px; "></ion-skeleton-text>        

    <p style="width:100px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
    <p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
    <p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
    <p style="display: inline-block; margin-right:15px; width:40px"><ion-skeleton-text animated style="width: 100%"></ion-skeleton-text></p>
</div>
      