import { Injectable }               from '@angular/core';
import { HttpClient, HttpHeaders}   from "@angular/common/http";
import { Connectivity }       	    from './connectivity_provider';
import { LocalStorageService }      from "./localStorage.service";
import { MessagePresenter }         from "./message.service";
import { Router }                   from '@angular/router';

import { Browser }                  from '@capacitor/browser';

import { UpdateService }            from '../services/update.service';

export function getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
}

import { API_URL } from '../inc.global';


@Injectable()
export class API {  

    downloadProgress : number = 0;
    ApiURL          = null;

    headers = new HttpHeaders({'Content-Type': 'application/json'});
    headersRaw = new HttpHeaders({'Content-Type': 'text/plain'})

    // TODO RECUPERAR ESTOS VALORES DE LA API
    VIMEO_ACCESS    = "cb0d51656f8a3d125e2d65c6875b4c3e";
    
    constructor(public http:HttpClient,
                public messagePresenter: MessagePresenter,
                private storage: LocalStorageService,
                private Connectivity: Connectivity,
                private router:Router,
                private updater: UpdateService

                ) {            
                    this.ApiURL = API_URL;
                    
                    //console.log(this.ApiURL);
                    
    }
    
    getAwsData (bucket="linkedvet") {
        const url = this.ApiURL + "aws-signature?bucket=" + bucket;
        return this.noTokenRequest(url);
    }

    public getPerfil(UsuarioId) {
        const url = this.ApiURL + "usuario-perfil/" + UsuarioId;
        return this.tokenRequest(url,false);
    }

    public checkPerfilCompleto() {
        const url = this.ApiURL + "check-perfil-completo";
        return this.tokenRequest(url,false);
    }

    // Paginas 
    public getPaginas() {
        const url = this.ApiURL + "paginas";
        return this.tokenRequest(url,false);
    }

    public getDatosPagina(id) {
        const url = this.ApiURL + "pagina/" + id;
        return this.tokenRequest(url, false);
    }

    public nuevaPagina(urlPagina:string) {
        const datos = { url : urlPagina};
        const url = this.ApiURL + "pagina";
        return this.tokenRequestPost(url, datos); 
    }

    public buscaPaginas(strBusqueda) {
        const datos = { StrBusqueda: strBusqueda};
        const url = this.ApiURL + "paginas-busca";
        return this.tokenRequestPost(url, datos);
    }

    public borrarPagina(paginaId) {
        const datos = { id: paginaId};
        const url = this.ApiURL + "pagina-delete";
        return this.tokenRequestPost(url, datos);
    }

    public seleccionarPaginaUsuario(paginaId) {
        const datos = { id: paginaId};
        const url = this.ApiURL + "pagina-add-usuario";
        return this.tokenRequestPost(url, datos);
    }

    public loginUser(datos:any) {
        const url = this.ApiURL + "login";
        return this.noTokenRequestPost(datos,url,false);   
    }

    public modificaPerfil(datos:any) {
        let obj = {};
        let contador = 0;
        datos.especies.forEach(
            element => { obj[contador] = element[0]; contador++; }
        );
        const jsonEspecies = JSON.stringify(obj);

        const objAreas = {};
        let contadorAreas = 0;
        datos.areas_interes.forEach(
            element => { objAreas[contadorAreas] = element[0]; contadorAreas++; }
        );
        const jsonAreasInteres = JSON.stringify(objAreas);

        const datosEnviar = { 
                                nombre:                 datos.nombre,
                                descripcion:            datos.descripcion,
                                foto:                   datos.foto,
                                foto_portada:           datos.foto_portada,
                                email:                  datos.email,
                                
                                actividad:              datos.actividad,
                                siglas:                 datos.siglas,
                                prefijo:                datos.prefijo,
                                telefono:               datos.telefono,
                                locale:                 datos.locale,
                                pais:                   datos.pais,
                                provincia:              datos.provincia,
                                universidad:            datos.universidad,
                                
                                acepto_comunicados:     datos.acepto_comunicados,

                                especies:               jsonEspecies,
                                areas_interes:          jsonAreasInteres,
                                
                                acreditacion_diploma:   datos.acreditacion_diploma,
                                acreditacion_master:    datos.acreditacion_master,
                                acreditacion_doctorado: datos.acreditacion_doctorado,
                                acreditacion_otros:     datos.acreditacion_otros,
                            }

        const url = this.ApiURL + "usuario-perfil-modifica";
        return this.tokenRequestPost(url, datosEnviar);
    }
    
    public registroUsuario(datos:any) {
        let obj = {};
        let contador = 0;
        datos.especies.forEach(
            element => { obj[contador] = element[0]; contador++; }
        );
        const jsonEspecies = JSON.stringify(obj);

        const objAreas = {};
        let contadorAreas = 0;
        datos.areas_interes.forEach(
            element => { objAreas[contadorAreas] = element[0]; contadorAreas++; }
        );
        const jsonAreasInteres = JSON.stringify(objAreas);

        const datosEnviar = { 
                                locale:     datos.locale,
                                nombre:     datos.nombre,
                                telefono:   datos.telefono,
                                email:      datos.email,
                                password:   datos.password,
                                pais:       datos.pais,
                                provincia:  datos.provincia,
                                titulo_foto: datos.titulo_foto,
                                
                                especies:           jsonEspecies,
                                areas_interes:      jsonAreasInteres,
                                actividad:          datos.actividad,
                                acepto_comunicados:     datos.acepto_comunicados,
                                aceptadas_condiciones:     datos.aceptadas_condiciones,
                                
                                acreditacion_diploma:   datos.acreditacion_diploma,
                                acreditacion_master:  datos.acreditacion_master,
                                acreditacion_doctorado: datos.acreditacion_doctorado,
                                acreditacion_otros:     datos.acreditacion_otros,

                                geo_calling_code:   datos.geo_calling_code,
                                geo_country_code:   datos.geo_country_code,
                                geo_ip:             datos.geo_ip,
                                geo_latitude:       datos.geo_latitude,
                                geo_longitude:      datos.geo_longitude,
                            }

        const url = this.ApiURL + "usuario-registro";
        return this.noTokenRequestPost(datosEnviar, url);
    }

    public checkEmailUnique(email) {
        const datos = { email: email };
        const url = this.ApiURL + "check-email-unique";
        return this.noTokenRequestPost(datos, url, false);
    }

    public getConfiguraciones() {
        const url = this.ApiURL + "configuraciones";
        return this.noTokenRequest(url,false);
    }

    // RED SUGERIDOS + SEGUIDORES + SEGUIDOS + BLOQUEADOS
    
    public getSugeridos(page, tipo, perpage=null){
        const url = this.ApiURL + `red-sugeridos-${tipo}?page=${page}&perpage=${perpage}`;
        return this.tokenRequest(url,false);
    }

    public conectarConSugerido(sugerido) {
        const datos = { idUsuario : sugerido.id};
        const url = this.ApiURL + "red-sugerencia-conectar";
        return this.tokenRequestPost(url, datos); 
    }

    public rechazarSugerido(sugerido) {
        const datos = { idRechazado : sugerido.id };
        const url = this.ApiURL + "red-sugerencia-rechazar";
        return this.tokenRequestPost(url, datos);
    }

    public conectarSugerido(sugerido) {
        const datos = { idUsuario : sugerido.id };
        const url = this.ApiURL + "red-sugerencia-conectar";
        return this.tokenRequestPost(url, datos);
    }

    public getSeguidos(page, strBusqueda, usuarioId=null) {
        if (strBusqueda=='') strBusqueda = 'all';
        const datos = { page: page, strBusqueda: strBusqueda, UsuarioId: usuarioId };
        const url = this.ApiURL + "red-seguidos";
        return this.tokenRequestPost(url, datos);
    }

    public getSeguidores(page, strBusqueda, usuarioId=null) {
        if (strBusqueda=='') strBusqueda = 'all';
        const datos = { page: page, strBusqueda: strBusqueda, UsuarioId: usuarioId };
        const url = this.ApiURL + "red-seguidores";
        return this.tokenRequestPost(url, datos);
    }

    public getSeguidosComunes(page, strBusqueda, usuarioId=null) {
        if (strBusqueda=='') strBusqueda = 'all';
        const datos = { page: page, strBusqueda: strBusqueda, UsuarioId: usuarioId };
        const url = this.ApiURL + "red-seguidos-comunes";
        return this.tokenRequestPost(url, datos);
    }

    public getSeguidosNumber() {
        const url = this.ApiURL + "red-seguidos-number";
        return this.tokenRequest(url, false);
    }

    public getSeguidoresNumber() {
        const url = this.ApiURL + "red-seguidores-number";
        return this.tokenRequest(url, false);
    }

    public getPaginasNumber() {
        const url = this.ApiURL + "red-paginas-number";
        return this.tokenRequest(url, false);
    }

    public noSeguir(id) {
        const datos = { idUsuario : id };
        const url = this.ApiURL + "red-no-seguir";
        this.updater.followUsuario();
        return this.tokenRequestPost(url, datos);
    }

    public seguir(id) {
        const datos = { idUsuario : id };
        const url = this.ApiURL + "red-seguir";
        this.updater.followUsuario();
        return this.tokenRequestPost(url, datos);
    }

    public bloquear(id) {
        const datos = { idUsuario : id };
        const url = this.ApiURL + "red-bloquear";
        return this.tokenRequestPost(url, datos);
    }

    public desbloquear(id) {
        var datos = { idUsuario : id };
        const url = this.ApiURL + "red-desbloquear";
        return this.tokenRequestPost(url, datos);
    }

    public searchPeople(searchTerm, perpage = 20) {
        const url = this.ApiURL + "busca-gente/" + searchTerm + "?perpage=" + perpage;
        return this.tokenRequest(url, false);
    }

    // ANUNCIOS
    public getAnuncios(page, tipo, subtipo, pais, provincia, destacado, palabras, orderby) {
        const datos = { tipo: tipo,
                        subtipo: subtipo,
                        pais: pais, 
                        provincia: provincia,
                        destacado: destacado, 
                        palabras: palabras,
                        orderby: orderby,
                        perpage: 12,
                        page: page
                    }
        
        const url = this.ApiURL + "anuncios/tipo";
        return this.tokenRequestPost(url, datos);
    }

    public getDetallesAnuncio(idAnuncio) {
        const url = this.ApiURL + "anuncio/id/" + idAnuncio;
        return this.tokenRequest(url, false);
    }

    // TODO falta pasar datos del formulario
    public enviarContacto(DatosContacto) {
        
        const datos = { 
                        IdAnuncio:      DatosContacto.referencia,
                        Nombre:         DatosContacto.nombre,
                        Telefono:       DatosContacto.telefono,
                        Email:          DatosContacto.email,
                        Comentarios:    DatosContacto.comentario
                    };
        const url = this.ApiURL + "anuncio/contactar";
        return this.tokenRequestPost(url, datos);
    }

    public enviarSoporte(DatosContacto) {
        
        const datos = { 
                        Tipo:           DatosContacto.tipo,
                        Nombre:         DatosContacto.nombre,
                        Email:          DatosContacto.email,
                        Asunto:         DatosContacto.asunto,
                        Descripcion:    DatosContacto.descripcion,
                        Foto:           DatosContacto.foto
                    };
        const url = this.ApiURL + "soporte";
        return this.tokenRequestPost(url, datos);
    }

    public getAnunciosPublicados(page, perpage = 15) {
        // paginados en backend
        const url = this.ApiURL + "mis-anuncios" + "?page=" + page + "&perpage=" + perpage;
        return this.tokenRequest(url, false);
    }

    public getAnunciosContactados (page, perpage = 20) {
        // paginados en backend
        const url = this.ApiURL + "mis-anuncios-contactados" + "?page=" + page + "&perpage=" + perpage;
        return this.tokenRequest(url, false);
    }

    public getInteresados(idAnuncio) {
        const url = this.ApiURL + "anuncio-interesados/" + idAnuncio;
        return this.tokenRequest(url, false);
    }

    public renovarPublicado(anuncio) {
        const datos = { anuncio: anuncio.id };
        const url = this.ApiURL + "anuncio/renovar";
        return this.tokenRequestPost(url, datos);
    }

    public eliminarPublicado(anuncio) {
        const datos = { anuncio: anuncio.id };
        const url = this.ApiURL + "anuncio/delete";
        return this.tokenRequestPost(url, datos);
    }

    public eliminarContactado(anuncio) {
        const datos = { anuncio: anuncio.id };
        const url = this.ApiURL + "anuncio-contactado/delete";
        return this.tokenRequestPost(url, datos);
    }

    public publicarAnuncio(datosAnuncio) {

        const datos = {     subtipo: datosAnuncio.subtipo,
                            tipo: datosAnuncio.tipo,
                            titulo: datosAnuncio.titulo, 
                            descripcion: datosAnuncio.descripcion,
                            //precio: datosAnuncio.precio,
                            foto: datosAnuncio.foto,
                            moneda_iso: datosAnuncio.moneda_iso
                    };
        
        const url = this.ApiURL + "anuncio/publicar";

        this.updater.nuevoAnuncio();
        return this.tokenRequestPost(url, datos);
    }

    public editarAnuncio(datosAnuncio) {
        
        const datos = {   
            id:         datosAnuncio.id,
            subtipo:    datosAnuncio.subtipo,
            tipo:       datosAnuncio.tipo,
            titulo:     datosAnuncio.titulo, 
            descripcion: datosAnuncio.descripcion,
            //precio:     datosAnuncio.precio,
            foto:       datosAnuncio.foto
        };
        
        const url = this.ApiURL + "anuncio/editar";
        return this.tokenRequestPost(url, datos);
        
       
    }

    public getSubtiposAnuncio(idioma) {
        const url = this.ApiURL + "anuncios-subtipos-material/idioma/" + idioma;
        return this.tokenRequest(url, false);
    }

    // localizacion
    public getPaises() {
        const url = this.ApiURL + "localizacion-paises";
        return this.noTokenRequest(url, false);
    } 

    public getProvincias(idPais) {
        const url = this.ApiURL + "localizacion-provincias/" + idPais;
        return this.noTokenRequest(url, false);    
    }

    public getUniversidades(idPais) {
        const url = this.ApiURL + "universidades/" + idPais;
        return this.tokenRequest(url, false);
    }

    public buscaUniversidad(strBusqueda) {
        const datos = { StrBusqueda: strBusqueda };
        const url = this.ApiURL + "universidad-busca";
        return this.tokenRequestPost(url, datos);
    }

    public borraUniversidadUsuario() {
        const url = this.ApiURL + "universidad-usuario-borra";
        return this.tokenRequest(url, false);
    }

    public getCargos(idioma) {
        const url = this.ApiURL + "cargos/" + idioma;
        return this.noTokenRequest(url, false);
    }

    public getActividades(idioma) {
        const url = this.ApiURL + "actividades-profesionales/" + idioma;
        return this.noTokenRequest(url, false);
    }
    
    // CHATS Y MENSAJES
    
    getConversaciones(pagina) {
        const datos = { page: pagina};
        const url = this.ApiURL + "mensajes-chats";
        return this.tokenRequestPost(url, datos);
    }
    
    buscarConversaciones(strBusqueda) {
        const datos = { StrBusqueda: strBusqueda };
        const url = this.ApiURL + "mensajes-chats-buscar";
        return this.tokenRequestPost(url, datos);
    }
    
    getMensajesChat(idChat, pagina) {
        const datos = { chat: idChat, page: pagina }
        const url = this.ApiURL + "mensajes-chat";
        return this.tokenRequestPost(url, datos);
    }

    enviaMensajePrivado(idChat, idDestinatario, MensajeFrm) {
        let datos = { IdChat: idChat, Destinatario: null, Mensaje: MensajeFrm };
        let url = this.ApiURL + "mensajes-nuevo";
        
        if (!idChat) {
            // juraría que aquí no entra nunca
            datos = { IdChat: null, Destinatario: idDestinatario, Mensaje: MensajeFrm };
            url = this.ApiURL + "mensajes-chat-nuevo";
        }
        
        return this.tokenRequestPost(url, datos);
    }

    chatDestinatarios(strBusqueda) {
        const datos = { StrBusqueda: strBusqueda};
        const url = this.ApiURL + "mensajes-chat-destinatarios";
        return this.tokenRequestPost(url, datos);
        
    }

    mensajesPendientes() {
        const url = this.ApiURL + "mensajes-pendientes";
        return this.tokenRequest(url,false);
    }

    getChatId(destinatarioId) {
        const datos = { destinatarioId: destinatarioId };
        const url = this.ApiURL + "mensajes-chat-id";
        return this.tokenRequestPost(url, datos);
    }

    // FEED
    getPostsActivity(pagina, UsuarioId) {
        const datos = { page: pagina, UsuarioId: UsuarioId};
        const url = this.ApiURL + "posts-activity";
        return this.tokenRequestPost(url, datos);
    }

    /*
    getPosts(pagina, loader) {
        let url = this.ApiURL + "posts?page=" + pagina;
        return this.tokenRequest(url,loader);
    }
    */

    getFeed(pagina, loader, perpage=8) {
        const url = this.ApiURL + "feed?page=" + pagina + "&perpage=" + perpage;
        return this.tokenRequest(url,loader);
    }

    getGuardados(pagina, perpage=8) {
        const url = this.ApiURL + "posts-guardados?page=" + pagina + "&perpage=" + perpage;
        return this.tokenRequest(url,false);
    }

    getFeedSecundario(pagina, idioma, perpage) {
        const datos = { Idioma: idioma, page: pagina, perpage: perpage};
        const url = this.ApiURL + "feed-secundario";
        return this.tokenRequestPost(url, datos);
    }
    
    getTopPostsOut(pagina, idioma, limit=10) {
        const datos = { Idioma: idioma, page: pagina, Limit: limit};
        const url = this.ApiURL + "posts-top-out";
        return this.tokenRequestPost(url, datos);
    }

    getPost(postId) {
        const url = this.ApiURL + "post/" + postId;
        return this.tokenRequest(url, false);
    }

    likePost(postId, status) {
        const datos = { PostId: postId, Status: status};
        const url = this.ApiURL + "post-like";
        return this.tokenRequestPost(url, datos);
    }

    guardadoPost(postId, status) {
        const datos = { PostId: postId, Status: status};
        const url = this.ApiURL + "post-guardado";
        return this.tokenRequestPost(url, datos);
    }

    recomiendaPost(postId, status) {
        const datos = { PostId: postId, Status: status};
        const url = this.ApiURL + "post-recomendar";
        return this.tokenRequestPost(url, datos);
    }

    getComentarios(postId, page, perpage=10) {
        const datos = { PostId: postId, page: page, perpage: perpage };
        const url = this.ApiURL + "post-comentarios";
        return this.tokenRequestPost(url, datos);
    }

    enviaComentario(postId, message) {
        const datos = { PostId: postId, Mensaje: message };
        const url = this.ApiURL + "post-nuevo-comentario";
        return this.tokenRequestPost(url, datos);
    }

    borraPostComentario(postId, comentarioId) {
        const datos = { PostId: postId, ComentarioId: comentarioId};
        const url = this.ApiURL + "post-comentario-borrar";
        return this.tokenRequestPost(url, datos);
    }

    enviaPublicacion(message) {
        const datos = { Mensaje: message};
        const url = this.ApiURL + "post-nuevo"; 
        return this.tokenRequestPost(url, datos);
    }

    postEnviaAnexosPublicacion(postId, anexos, tipo) {
        const datos = { PostId: postId, Anexos: anexos, Tipo: tipo};
        const url = this.ApiURL + "post-anexos";
        return this.tokenRequestPost(url, datos);
    }

    webinarComentarioEnviaAnexosPublicacion(webinarComentarioId, anexos, tipo) {
        const datos = { WebinarComentarioId: webinarComentarioId, Anexos: anexos, Tipo: tipo};
        const url = this.ApiURL + "webinar-comentario-anexos";
        return this.tokenRequestPost(url, datos);
    }

    borraWebinarComentario(comentarioId) {
        const url = this.ApiURL + "webinar-comentario/" + comentarioId;
        return this.tokenDelete(url, false);
    }

    enviaAnexosMensaje(mensajeId, anexos, tipo) {
        const datos = { MensajeId: mensajeId, Anexos: anexos, Tipo: tipo};
        const url = this.ApiURL + "mensaje-anexos";
        return this.tokenRequestPost(url, datos);
    }

    async enviaAnexosComentario(comentarioId, anexos, tipo) {
        const datos = { ComentarioId: comentarioId, Anexos: anexos, Tipo: tipo};
        const url = this.ApiURL + "post-comentario-anexos";
        return await this.tokenRequestPost(url, datos);
    }

    borraPost(postId) {
        const datos = { PostId: postId};
        const url = this.ApiURL + "post-borrar";
        return this.tokenRequestPost(url, datos);
    }

    editarPost(postId, mensaje) {
        const datos = { PostId: postId, Mensaje: mensaje};
        const url = this.ApiURL + "post-editar";
        return this.tokenRequestPost(url, datos);
    }

    getTopPosts(pagina, cargoId=null, especieId=null, idioma, limit=10) {
        if (!cargoId && !especieId) return;
        const datos = { CargoId: cargoId, EspecieId: especieId, Idioma: idioma, page: pagina, Limit: limit};
        
        const url = this.ApiURL + "posts-top";
        return this.tokenRequestPost(url, datos);
    } 

    buscaPosts(searchTerm, perpage) {
        const url = this.ApiURL + "posts-buscar/" + searchTerm + "?perpage=" + perpage;
        return this.tokenRequest(url, false);
    }

    postEnviaTags(postId, tags) {
        const datos = { postId : postId, tags: tags };
        const url = this.ApiURL + "post-guarda-tags";
        return this.tokenRequestPost(url, datos);
    }

    postEnviaUsuarios(postId, usuarios, hash) {
        const datos = { postId : postId, usuarios: usuarios, postHash:hash };
        const url = this.ApiURL + "post-guarda-usuarios";
        return this.tokenRequestPost(url, datos);
    }

    postEnviaComentarioUsuarios(postId, postComentarioId, usuarios, hash) {
        const datos = { postId : postId, usuarios: usuarios, postComentarioId: postComentarioId, postHash:hash};
        const url = this.ApiURL + "post-comentario-guarda-usuarios";
        return this.tokenRequestPost(url, datos);
    }

    getIdiomasDisponibles() {
        const url = this.ApiURL + "idiomas-disponibles";
        return this.tokenRequest(url,false);    
    }

    // CV

    dameCvExperiencias(idUsuario=null) {
        const url = this.ApiURL + "cv-experiencias/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvEstudios(idUsuario=null) {
        const url = this.ApiURL + "cv-estudios/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvCursos(idUsuario=null) {
        const url = this.ApiURL + "cv-cursos/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvPublicaciones(idUsuario=null) {
        const url = this.ApiURL + "cv-publicaciones/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvInformatica(idUsuario=null) {
        const url = this.ApiURL + "cv-informatica/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvMeritos(idUsuario=null) {
        const url = this.ApiURL + "cv-meritos/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvIdiomas(idUsuario=null) {
        const url = this.ApiURL + "cv-idiomas/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvAsociaciones(idUsuario=null) {
        const url = this.ApiURL + "cv-asociaciones/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvOtros(idUsuario=null) {
        const url = this.ApiURL + "cv-otros/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvPreferencias(idUsuario=null) {
        const url = this.ApiURL + "cv-preferencias/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameCvHabilidades(idUsuario=null) {
        const url = this.ApiURL + "cv-habilidades/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    dameFechaUpdate(idUsuario=null) {
        const url = this.ApiURL + "cv-fecha-update/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    // bloques CV
    asociacionNew(formulario) {
        const datos = { nombre: formulario.nombre, siglas: formulario.siglas};
        const url = this.ApiURL + "cv-asociacion-new";
        return this.tokenRequestPost(url, datos);
    }

    asociacionEdit(formulario) {
        const datos = { id: formulario.id, nombre: formulario.nombre, siglas: formulario.siglas};
        const url = this.ApiURL + "cv-asociacion-edit";        
        return this.tokenRequestPost(url, datos);
    }

    asociacionDelete(idAsociacion) {
        const datos = { id: idAsociacion};
        const url = this.ApiURL + "cv-asociacion-delete";
        return this.tokenRequestPost(url, datos);
    }

    cursoNew(formulario) {
        const datos = { 
                        titulo:             formulario.titulo,
                        descripcion:        formulario.descripcion,
                        fecha_fin:          formulario.fecha_fin,
                        centro_nombre:      formulario.centro_nombre,
                        tipo_asistencia:    formulario.tipo_asistencia,
                        horas:              formulario.horas,
                        titulo_foto:        formulario.titulo_foto,
                        titulo_pdf:         formulario.titulo_pdf,
                        titulo_pdf_nombre:  formulario.titulo_pdf_nombre
        }
        
        const url = this.ApiURL + "cv-curso-new";
        return this.tokenRequestPost(url, datos);
    }

    cursoEdit(formulario) {
        const datos = { 
            id:                 formulario.id, 
            titulo:             formulario.titulo,
            descripcion:        formulario.descripcion,
            fecha_fin:          formulario.fecha_fin,
            centro_nombre:      formulario.centro_nombre,
            tipo_asistencia:    formulario.tipo_asistencia,
            horas:              formulario.horas,
            titulo_foto:        formulario.titulo_foto,
            titulo_pdf:         formulario.titulo_pdf,
            titulo_pdf_nombre:  formulario.titulo_pdf_nombre

}
        const url = this.ApiURL + "cv-curso-edit";        
        return this.tokenRequestPost(url, datos);
    }

    cursoDelete(idCurso) {
        const datos = { id: idCurso};
        const url = this.ApiURL + "cv-curso-delete";
        return this.tokenRequestPost(url, datos);
    }

    estudioNew(formulario) {
        const datos = { 
            titulo:                 formulario.titulo,
            descripcion:            formulario.descripcion,
            fecha_inicio:           formulario.fecha_inicio,
            fecha_fin:              formulario.fecha_fin,
            centro_nombre:          formulario.centro_nombre,
            cursando_actualmente:   formulario.cursando_actualmente,
            titulo_foto:        formulario.titulo_foto,
            titulo_pdf:         formulario.titulo_pdf,
            titulo_pdf_nombre:  formulario.titulo_pdf_nombre
        }
        
        const url = this.ApiURL + "cv-estudio-new";
        return this.tokenRequestPost(url, datos);
    }

    estudioEdit(formulario) {
        const datos = { 
            id:                     formulario.id, 
            titulo:                 formulario.titulo,
            descripcion:            formulario.descripcion,
            fecha_inicio:           formulario.fecha_inicio,
            fecha_fin:              formulario.fecha_fin,
            centro_nombre:          formulario.centro_nombre,
            cursando_actualmente:   formulario.cursando_actualmente,
            titulo_foto:        formulario.titulo_foto,
            titulo_pdf:         formulario.titulo_pdf,
            titulo_pdf_nombre:  formulario.titulo_pdf_nombre
        }
                
        const url = this.ApiURL + "cv-estudio-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    estudioDelete(idEstudio) {
        const datos = { id: idEstudio};
        const url = this.ApiURL + "cv-estudio-delete";
        return this.tokenRequestPost(url, datos);
    }

    experienciaNew(formulario) {
        const datos = { 
            empresa_nombre:     formulario.empresa_nombre,
            puesto_nombre:      formulario.puesto_nombre,
            puesto_descripcion: formulario.puesto_descripcion,
            fecha_inicio:       formulario.fecha_inicio,
            fecha_fin:          formulario.fecha_fin,
            practicas:          formulario.practicas,
            carta_pdf:          formulario.carta_pdf,
            carta_pdf_nombre:   formulario.carta_pdf_nombre,
            certificado_pdf:    formulario.certificado_pdf,
            certificado_pdf_nombre:   formulario.certificado_pdf_nombre,
        }
        
        const url = this.ApiURL + "cv-experiencia-new";
        return this.tokenRequestPost(url, datos);
    }

    experienciaEdit(formulario) {
        const datos = { 
            id:                 formulario.id, 
            empresa_nombre:     formulario.empresa_nombre,
            puesto_nombre:      formulario.puesto_nombre,
            puesto_descripcion: formulario.puesto_descripcion,
            fecha_inicio:       formulario.fecha_inicio,
            fecha_fin:          formulario.fecha_fin,
            practicas:          formulario.practicas,
            carta_pdf:          formulario.carta_pdf,
            carta_pdf_nombre:   formulario.carta_pdf_nombre,
            certificado_pdf:    formulario.certificado_pdf,
            certificado_pdf_nombre:   formulario.certificado_pdf_nombre,
        }
                
        const url = this.ApiURL + "cv-experiencia-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    experienciaDelete(idExperiencia) {
        const datos = { id: idExperiencia};
        const url = this.ApiURL + "cv-experiencia-delete";
        return this.tokenRequestPost(url, datos);
    }

    idiomaNew(formulario) {
        const datos = { 
            idioma:         formulario.idioma,
            nivel:          formulario.nivel,
            comentarios:    formulario.comentarios
        }
        
        const url = this.ApiURL + "cv-idioma-new";
        return this.tokenRequestPost(url, datos);
    }

    idiomaEdit(formulario) {
        const datos = { 
            id:             formulario.id, 
            idioma:         formulario.idioma,
            nivel:          formulario.nivel,
            comentarios:    formulario.comentarios
        }
                
        const url = this.ApiURL + "cv-idioma-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    idiomaDelete(idIdioma) {
        const datos = { id: idIdioma};
        const url = this.ApiURL + "cv-idioma-delete";
        return this.tokenRequestPost(url, datos);
    }

    informaticaNew(formulario) {
        const datos = { 
            nivel:  formulario.nivel,
            titulo: formulario.titulo
        }
        
        const url = this.ApiURL + "cv-informatica-new";
        return this.tokenRequestPost(url, datos);
    }

    informaticaEdit(formulario) {
        const datos = { 
            id:     formulario.id, 
            nivel:  formulario.nivel,
            titulo: formulario.titulo
        }
                
        const url = this.ApiURL + "cv-informatica-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    informaticaDelete(idInformatica) {
        const datos = { id: idInformatica};
        const url = this.ApiURL + "cv-informatica-delete";
        return this.tokenRequestPost(url, datos);
    }

    meritoNew(formulario) {
        const datos = { 
            titulo:         formulario.titulo,
            descripcion:    formulario.descripcion,
            fecha:          formulario.fecha
        }
        
        const url = this.ApiURL + "cv-merito-new";
        return this.tokenRequestPost(url, datos);
    }

    meritoEdit(formulario) {
        const datos = { 
            id:             formulario.id, 
            titulo:         formulario.titulo,
            descripcion:    formulario.descripcion,
            fecha:          formulario.fecha
        }
                
        const url = this.ApiURL + "cv-merito-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    meritoDelete(idMerito) {
        const datos = { id: idMerito};
        const url = this.ApiURL + "cv-merito-delete";
        return this.tokenRequestPost(url, datos);
    }

    enviarPostMail(post, email: string, descripcion : string, imgAnexo:string = null) {
        const datos = { PostHash: post.hash_id, Email: email, PostDescripcion: descripcion, ImgAnexo: imgAnexo};
        const url = this.ApiURL + "post-compartir-email";
        return this.tokenRequestPost(url, datos);
    }
    
    enviarParticipacionMail(participacion, email: string) {
        
        const datos = { ParticipacionHash: participacion.hash_id, Email: email, ParticipacionDescripcion: participacion.descripcion, ImgAnexo: participacion.foto_url};
        const url = this.ApiURL + "participacion-compartir-email";
        return this.tokenRequestPost(url, datos);
    }

    enviarConcursoMail(datosConcurso, email: string) {
        const datos = { Url: 'https://app.yosvet.com/tabs/contests/detail/' + datosConcurso.id, Email: email, ConcursoNombre: datosConcurso.titulo, ConcursoFoto: datosConcurso.banner};
        const url = this.ApiURL + "concurso-compartir-email";
        return this.tokenRequestPost(url, datos);
    }

    enviarEventoMail(datosEvento, email: string) {
        
        let urlEvento = 'https://app.yosvet.com/tabs/courses/detail/' + datosEvento.id;
        if (datosEvento.tipo='WEBINAR') urlEvento = 'https://app.yosvet.com/tabs/masterclass/detail/' + datosEvento.id;
        const datos = { Url: urlEvento, Email: email, EventoNombre: datosEvento.titulo, EventoFoto: datosEvento.foto, EventoTipo: datosEvento.tipo };
        const url = this.ApiURL + "evento-compartir-email";
        return this.tokenRequestPost(url, datos);
    }

    enviarAnuncioMail(datosAnuncio, email: string) {
        const datos = { Url: 'https://app.yosvet.com/tabs/ads/ad-detail/' + datosAnuncio.id, Email: email, AnuncioNombre: datosAnuncio.titulo, AnuncioFoto: datosAnuncio.foto };
        const url = this.ApiURL + "anuncio-compartir-email";
        return this.tokenRequestPost(url, datos);
    }

    enviarEmpleoMail(datosEmpleo, email: string) {
        const datos = { Url: 'https://app.yosvet.com/tabs/employ/detail/' + datosEmpleo.id, Email: email, EmpleoNombre: datosEmpleo.titulo };
        const url = this.ApiURL + "empleo-compartir-email";
        return this.tokenRequestPost(url, datos);
    }

    publicacionNew(formulario) {
        const datos = { 
            tipo:           formulario.tipo,
            descripcion:    formulario.descripcion,
            fecha:          formulario.fecha
        }
        
        const url = this.ApiURL + "cv-publicacion-new";
        return this.tokenRequestPost(url, datos);
    }

    publicacionEdit(formulario) {
        const datos = { 
            id:             formulario.id, 
            tipo:           formulario.tipo,
            descripcion:    formulario.descripcion,
            fecha:          formulario.fecha
        }
                
        const url = this.ApiURL + "cv-publicacion-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    publicacionDelete(idPublicacion) {
        const datos = { id: idPublicacion};
        const url = this.ApiURL + "cv-publicacion-delete";
        return this.tokenRequestPost(url, datos);
    }

    otrosEdit(formulario) {
        const datos = { 
            id:                 formulario.id, 
            permiso_conducir:   formulario.permiso_conducir,
            vehiculo_propio:    formulario.vehiculo_propio,
            autonomo:           formulario.autonomo,
            deporte:            formulario.deporte
        }
                
        const url = this.ApiURL + "cv-otros-edit";        
        return this.tokenRequestPost(url, datos);
    }

    preferenciasEdit(formulario) {
        const datos = { 
            id:                 formulario.id, 
            trabajas_actualmente:   formulario.trabajas_actualmente,
            buscando_empleo:    	formulario.buscando_empleo,
            disponibilidad_viajar:  formulario.disponibilidad_viajar,
			disponibilidad_residencia: formulario.disponibilidad_residencia,
            cv_pdf: formulario.cv_pdf,
            cv_pdf_nombre: formulario.cv_pdf_nombre,
        }
                
        const url = this.ApiURL + "cv-preferencias-edit";        
        return this.tokenRequestPost(url, datos);
    }

    habilidadesEdit(formulario) {
        const datos = { 
            id:                         formulario.id, 
            capacidad_aprendizaje:      formulario.capacidad_aprendizaje,
            orientado_detalle:          formulario.orientado_detalle,
            gestion_tiempo:             formulario.gestion_tiempo,
            habilidades_comunicacion:   formulario.habilidades_comunicacion,
            toma_decisiones:            formulario.toma_decisiones,
            resolucion_conflictos:      formulario.resolucion_conflictos,
            responsabilidad:            formulario.responsabilidad,
            trabajo_equipo:             formulario.trabajo_equipo,
            liderazgo:                  formulario.liderazgo,
            don_gentes:                 formulario.don_gentes,
            confiable:                  formulario.confiable,
            calma_presion:              formulario.calma_presion,
            servicio_cliente:           formulario.servicio_cliente,
            actitud_positiva:            formulario.actitud_positiva,
            resistencia_fisica:         formulario.resistencia_fisica
        }
                
        const url = this.ApiURL + "cv-habilidades-edit";        
        return this.tokenRequestPost(url, datos);
    }

    fotoCV(foto) {
        const datos = { 
            foto_perfil: foto
        }
     
        const url = this.ApiURL + "cv-foto-perfil";        
        return this.tokenRequestPost(url, datos);
    }

    fotoPerfil(foto) {
        const datos = { 
            foto_perfil: foto
        }
     
        const url = this.ApiURL + "perfil-foto";        
        return this.tokenRequestPost(url, datos);
    }

    fotoPerfilCabecera(foto) {
        const datos = { 
            foto_perfil_cabecera : foto
        }
     
        const url = this.ApiURL + "perfil-foto-cabecera";        
        return this.tokenRequestPost(url, datos);
    }

    getEspecies(Idioma) {
        if (!Idioma) Idioma = "ES";
        const url = this.ApiURL + "especies/" + Idioma;        
        return this.noTokenRequest(url, false);
    }

    getEspeciesDisponibles() {
        const url = this.ApiURL + "especies-disponibles";        
        return this.tokenRequest(url, false);
    }

    getEmpresas() {
        const url = this.ApiURL + "empresas";        
        return this.tokenRequest(url, false);
    }

    datosEmpresa(idEmpresa) {
        const url = this.ApiURL + "empresa/" + idEmpresa;        
        return this.tokenRequest(url, false);
    }

    getSectoresEmpleo() {
        const url = this.ApiURL + "sectores-empleo";        
        return this.tokenRequest(url, false);
    }

    getOfertasUsuario() {
        const url = this.ApiURL + "ofertas-usuario";
        return this.tokenRequest(url, false);
    }

    getOfertasPais(page, provincia, especie) {
        let url = this.ApiURL + "ofertas-pais?page=" + page;
        if (provincia!="" && provincia!="todas")  url = url + "&provincia=" + provincia;
        if (especie!="" && especie!="todas")   url = url + "&especie=" + especie;
        return this.tokenRequest(url, false);
    }

    empresaNew(formulario) {
        const datos = { 
            nombre: formulario.nombre,
            url:    formulario.url,
            logo:   formulario.logo,
        }
        
        const url = this.ApiURL + "empresa-new";
        return this.tokenRequestPost(url, datos);
    }

    empresaEdit(formulario) {
        const datos = { 
            id:     formulario.id, 
            nombre: formulario.nombre,
            url:    formulario.url,
            logo:   formulario.logo,
        }
                
        const url = this.ApiURL + "empresa-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    empresaDelete(idEmpresa) {
        const datos = { id: idEmpresa};
        const url = this.ApiURL + "empresa-delete";
        return this.tokenRequestPost(url, datos);
    }

    getDatosOferta(ofertaId) {
        const url = this.ApiURL + "oferta/" + ofertaId;
        return this.tokenRequest(url, false);
    }

    ofertaNew(formulario) {
        let obj = {};
        let contador = 0;
        formulario.especies.forEach(
            element => { obj[contador] = element[0]; contador++; }
        );
        const jsonEspecies = JSON.stringify(obj);

        let objPreguntas = {};
        let contadorPreguntas = 0;
        
        formulario.preguntas.forEach(
            element => { objPreguntas[contadorPreguntas] = element.texto; contadorPreguntas++; }
        );
        const jsonPreguntas = JSON.stringify(objPreguntas);

        const datos = { 
            titulo:	    		formulario.titulo,
			descripcion:		formulario.descripcion,
			empresa_id:			formulario.empresa_id,
			provincia_id:		formulario.provincia_id,
            sector_id:          formulario.sector_id,
			vacantes:			formulario.vacantes,
			jornada:			formulario.jornada,
            modalidad:			formulario.modalidad,
			requisitos:			formulario.requisitos,
			duracion_contrato:	formulario.duracion_contrato,
			salario_min:		formulario.salario_min,
			salario_max:		formulario.salario_max,
            moneda_id:          formulario.moneda_id,
			municipio:			formulario.municipio,
            especies:           jsonEspecies,
            preguntas:          jsonPreguntas

        }
        
        const url = this.ApiURL + "oferta-new";
        return this.tokenRequestPost(url, datos);
    }

    ofertaEdit(formulario) {

        let objEspecies = {};
        let contadorEspecies = 0;
        formulario.especies.forEach(
            element => { objEspecies[contadorEspecies] = element[0]; contadorEspecies++; }
        );
        const jsonEspecies = JSON.stringify(objEspecies);

        let objPreguntas = {};
        let contadorPreguntas = 0;
        
        formulario.preguntas.forEach(
            element => { objPreguntas[contadorPreguntas] = element.texto; contadorPreguntas++; }
        );
        const jsonPreguntas = JSON.stringify(objPreguntas);

        const datos = { 
            id:                 formulario.id, 
            titulo:	    		formulario.titulo,
			descripcion:		formulario.descripcion,
			empresa_id:			formulario.empresa_id,
			provincia_id:		formulario.provincia_id,
            sector_id:          formulario.sector_id,
			vacantes:			formulario.vacantes,
			jornada:			formulario.jornada,
            modalidad:			formulario.modalidad,
			requisitos:			formulario.requisitos,
			duracion_contrato:	formulario.duracion_contrato,
			salario_min:		formulario.salario_min,
			salario_max:		formulario.salario_max,
            moneda_id:          formulario.moneda_id,
			municipio:			formulario.municipio,
            especies:           jsonEspecies,
            preguntas:          jsonPreguntas
        }

        const url = this.ApiURL + "oferta-edit";        
        return this.tokenRequestPost(url, datos);
        
    }

    ofertaDelete(idOferta) {
        const datos = { id: idOferta};
        const url = this.ApiURL + "oferta-delete";
        return this.tokenRequestPost(url, datos);
    }

    

    ofertaFinalizar(idOferta) {
        const datos = { id: idOferta};
        const url = this.ApiURL + "oferta-finalizar";
        return this.tokenRequestPost(url, datos);
    }

    inscripcionOferta(idOferta, respuestasArray=null) {
        const jsonRespuestas = JSON.stringify(respuestasArray);
        const datos = {
            idOferta: idOferta,
            respuestas: jsonRespuestas
        }

        const url = this.ApiURL + "inscripcion";
        return this.tokenRequestPost(url, datos);
    }

    getPreguntas(idOferta) {
        const url = this.ApiURL + "preguntas/" + idOferta;
        return this.tokenRequest(url, false);
    }

    getOfertasActivas() {
        const url = this.ApiURL + "ofertas-activas";
        return this.tokenRequest(url, false);
    }

    getCandidaturas(page) {
        const url = this.ApiURL + "candidaturas?page=" + page;
        return this.tokenRequest(url, false);
    }

    getInscritosOferta(idOferta) {
        const url = this.ApiURL + "inscritos/" + idOferta;
        return this.tokenRequest(url, false);
    }

    cambiaEstadoInscripcion(idInscripcion, estado) {
        const datos = { 
            idInscripcion:  idInscripcion, 
            estado:         estado
        }
                        
        const url = this.ApiURL + "cambia-estado-inscripcion";        
        return this.tokenRequestPost(url, datos);
    }

    retirarInscripcion(idOferta) {
        const url = this.ApiURL + "retirar-inscripcion/" + idOferta;
        return this.tokenRequest(url, false);
    }

    getRespuestasOferta(idOferta, idUsuario) {
        const url = this.ApiURL + "respuestas-usuario/" + idOferta + "/" + idUsuario;        
        return this.tokenRequest(url, true);
    }

    checkPermisosVerCV(idUsuario) {
        const url = this.ApiURL + "cv-ver-permisos/" + idUsuario;
        return this.tokenRequest(url, false);
    }

    // CONCURSOS
    getConcursosActivos($limit=0) {
        const url = this.ApiURL + "concursos-activos/" + $limit;
        return this.noTokenRequest(url, false);
    }

    getConcursosHistorico() {
        const url = this.ApiURL + "concursos-historico";
        return this.tokenRequest(url, false);
    }

    getDatosConcurso(concursoId) {
        const url = this.ApiURL + "concurso/" + concursoId;
        return this.tokenRequest(url, false);
    }

    getParticipacionConcurso(concursoId) {
        const url = this.ApiURL + "concurso-participacion/" + concursoId;
        return this.tokenRequest(url, false);
    }

    guardaParticipacion(foto_url=null, video_url=null, concursoId, descripcion, nombre_concurso) {
        const datos = { 
            foto_url:       foto_url, 
            video_url:      video_url,
            concursoId:     concursoId,
            descripcion:    descripcion,
            NombreConcurso: nombre_concurso
        }
                        
        const url = this.ApiURL + "guarda-participacion";        
        return this.tokenRequestPost(url, datos);
    }

    borraParticipacion(idParticipacionMedia) {
        const url = this.ApiURL + "borra-participacion/" + idParticipacionMedia;
        return this.tokenRequest(url, false);
    }

    getParticipaciones() {
        const url = this.ApiURL + "participaciones";
        return this.tokenRequest(url, false);
    }

    getFotosParticipantes(concursoId) {
        const url = this.ApiURL + "participantes-fotos/" + concursoId + "?page=0";
        return this.tokenRequest(url, false);
    }

    getFotosParticipante(concursoId, usuarioId) {
        const url = this.ApiURL + "participante-fotos";
        const datos = { concursoId: concursoId, usuarioId: usuarioId};
        return this.tokenRequestPost(url, datos);
    }
    
    setVotacion(participacionId, valorVoto) {
        const datos = { 
            participacion_id:       participacionId, 
            voto:     valorVoto
        }
                        
        const url = this.ApiURL + "participacion-votar";        
        return this.tokenRequestPost(url, datos);
    }

    insertaJurado(concursoId) {
        const url = this.ApiURL + "inserta-jurado/" + concursoId;
        return this.tokenRequest(url, false);
    }

    getBoleto(concursoId) {
        const url = this.ApiURL + "boleto/" + concursoId;
        return this.tokenRequest(url, false);
    }

    getResultadoConcurso(concursoId, page) {
        const url = this.ApiURL + "concurso-resultado/" + concursoId + "?page=" + page;
        return this.tokenRequest(url, false);
    }

    getDetalleVotosParticipacion(participacionId) {
        const url = this.ApiURL + "detalle-votos/" + participacionId;
        return this.tokenRequest(url, false);
    }

    getGeolocalizacion() {
        const url = this.ApiURL + "geolocalizacion";
        return this.noTokenRequest(url, false);
    }

    // EVENTOS
    getCursos(page=1, TodosIdiomas) {
        const url = this.ApiURL + "cursos" + "?page=" + page + "&TodosIdiomas=" + TodosIdiomas;
        return this.tokenRequest(url,false);
    }

    getEventos(page=1, TodosIdiomas) {
        const url = this.ApiURL + "eventos" + "?page=" + page + "&TodosIdiomas=" + TodosIdiomas;
        return this.tokenRequest(url,false);
    }

    getEventosFav(page=1, TodosIdiomas) {
        const url = this.ApiURL + "eventos-fav" + "?page=" + page + "&TodosIdiomas=" + TodosIdiomas;
        return this.tokenRequest(url,false);
    }
    
    getCertificados(page=1) {
        const url = this.ApiURL + "eventos-certificados" + "?page=" + page;
        return this.tokenRequest(url,false);
    }

    getDatosEvento(eventoId) {
        const url = this.ApiURL + "evento/" + eventoId;
        return this.tokenRequest(url,false);
    }

    impartirForm(formulario) {
        const datos = { titulo: formulario.titulo, motivos: formulario.motivos };
        const url = this.ApiURL + "evento-impartir";
        return this.tokenRequestPost(url, datos);
    }

    getDatosWebinar(eventoId) {
        const url = this.ApiURL + "webinar/" + eventoId;
        return this.tokenRequest(url,false);
    }

    getExamWebinar(eventoId) {
        const url = this.ApiURL + "webinar-exam/" + eventoId;
        return this.tokenRequest(url, false);
    }

    sendExamWebinar(examen, eventoId) {
        const examenString = '' + examen.p1+examen.p2+examen.p3+examen.p4+examen.p5;
        const datos = { examen: examenString, webinarId: eventoId };
        const url = this.ApiURL + "webinar-exam";
        return this.tokenRequestPost(url, datos);
    }

    setVistoWebinar(webinarId) {
        const datos = { id: webinarId };
        const url = this.ApiURL + "webinar-visto";
        return this.tokenRequestPost(url, datos);
    }

    changeFavWebinar(webinarId, estado=null) {
        const datos = { id: webinarId, EstadoFavorito: estado };
        const url = this.ApiURL + "webinar-favorito";
        return this.tokenRequestPost(url, datos);
    }

    getComentariosWebinar(webinarId, page, orden) {
        //console.log("Cargando comentarios de la página ", page);
        const url = this.ApiURL + "webinar-comentarios/" + webinarId + "?page=" + page + "&orden=" + orden;
        return this.tokenRequest(url,false);
    }

    getCasosWebinar(webinarId) {
        const url = this.ApiURL + "webinar-casos/" + webinarId;
        return this.tokenRequest(url,false);
    }

    getDatosComentarioWebinar(eventoId, comentarioId) {
        const url = this.ApiURL + "webinar-comentario/webinar/" + eventoId + "/comentario/" + comentarioId;
        return this.tokenRequest(url, false);
    }

    enviaComentarioWebinar(mensaje, webinarId, case_=false, title:string='') {
        const datos = { id: webinarId, message: mensaje, caso:case_, titulo:title };
        const url = this.ApiURL + "webinar-comentario";
        return this.tokenRequestPost(url, datos);
    }

    aperturaWebinarCaso(webinarId, comentarioId, status) {
        const datos = { id: webinarId, comentario: comentarioId, estado: status};
        const url = this.ApiURL + "webinar-comentario-apertura";
        return this.tokenRequestPost(url, datos);
    }

    enviaComentarioRespuestaWebinar(mensaje, webinarId, comentarioId, case_=false) {
        const datos = { webinarId: webinarId, comentarioId: comentarioId, message: mensaje, caso:case_};
        const url = this.ApiURL + "webinar-comentario-respuesta";
        return this.tokenRequestPost(url, datos);
    }

    getRespuestasComentarioWebinar(eventoId, comentarioId, page, filtroMensajes="todas") {
        const url = this.ApiURL + "webinar-comentario-respuestas/webinar/" + eventoId + "/comentario/" + comentarioId + "?page=" + page + "&filtro=" + filtroMensajes;
        return this.tokenRequest(url,false);
    }

    changeLikeComentarioWebinar(eventoId, comentarioId) {
        const url = this.ApiURL + "webinar-comentario-like";
        const datos = { webinarId: eventoId, comentarioId: comentarioId };
        return this.tokenRequestPost(url, datos);
    }

    inscripcionWebinar(eventoId) {
        const url = this.ApiURL + "webinar-inscripcion/" + eventoId;
        return this.tokenRequest(url, false);
    }

    // notificaciones
    getNotificaciones(page) {
        const url = this.ApiURL + "notificaciones?page=" + page;
        return this.tokenRequest(url,false);
    }

    getNotificacionesUnreadNumber() {
        const url = this.ApiURL + "notificaciones-unread-number";
        return this.tokenRequest(url,false);
    }

    getNotificacionesUnreadNumberMessages() {
        const url = this.ApiURL + "notificaciones-unread-number-messages";
        return this.tokenRequest(url,false);
    }

    getNotificacionesUnreadNumberEventos() {
        const url = this.ApiURL + "notificaciones-unread-number-eventos";
        return this.tokenRequest(url,false);
    }

    getNotificacionesUnreadNumberEmpleo() {
        const url = this.ApiURL + "notificaciones-unread-number-empleo";
        return this.tokenRequest(url,false);
    }

    getNotificacionesUnreadNumberAnuncios() {
        const url = this.ApiURL + "notificaciones-unread-number-anuncios";
        return this.tokenRequest(url,false);
    }

    getNotificacionesUnreadNumberConcursos() {
        const url = this.ApiURL + "notificaciones-unread-number-concursos";
        return this.tokenRequest(url,false);
    }

    notificaPerfilVisto(IdUsuario){
        const url = this.ApiURL + "notifica-perfil-visto/" + IdUsuario;
        return this.tokenRequest(url, false);
    }

    deleteNotificacion(IdNotificacion) {
        const url = this.ApiURL + "notificacion/" + IdNotificacion;
        return this.tokenDelete(url, false);
    }

    marcaNotificacionesLeidas(tipoNotificacion) {
        const datos = { tipo: tipoNotificacion };
        const url = this.ApiURL + "set-notificaciones-leidas";
        return this.tokenRequestPost(url, datos);
    }

    // ALERTAS
    getAlertasUsuario() {
        const url = this.ApiURL + "alertas";
        return this.tokenRequest(url, false);
    }

    alertaNew(formulario) {
        let obj = {};
        let contador = 0;
        formulario.especies.forEach(
            element => { obj[contador] = element[0]; contador++; }
        );
        const jsonEspecies = JSON.stringify(obj);

        const datos = { 
			pais_id:			formulario.pais,
			provincia_id:		formulario.provincia,
            sector_id:          formulario.sector,
            notifica_email:     formulario.notifica_email,
            especies:           jsonEspecies
        }
        
        const url = this.ApiURL + "alerta-new";
        return this.tokenRequestPost(url, datos);
    }

    alertaDelete(idAlerta) {
        const datos = { id: idAlerta};
        const url = this.ApiURL + "alerta-delete";
        return this.tokenRequestPost(url, datos);
    }

    vimeoPostSetPlayableStatus(anexoId) {
        const url = this.ApiURL + "vimeo-post-set-playable/" + anexoId;
        return this.tokenRequest(url,false);
    }

    vimeoWebinarSetPlayableStatus(anexoId) {
        const url = this.ApiURL + "vimeo-webinar-set-playable/" + anexoId;
        return this.tokenRequest(url,false);
    }

    vimeoMensajeSetPlayableStatus(anexoId) {
        const url = this.ApiURL + "vimeo-mensaje-set-playable/" + anexoId;
        return this.tokenRequest(url,false);
    }

    vimeoConcursoSetPlayableStatus(participacionMediaId) {
        const url = this.ApiURL + "vimeo-concurso-set-playable/" + participacionMediaId;
        return this.tokenRequest(url,false);
    }

    vimeoComentarioSetPlayableStatus(anexoId) {
        const url = this.ApiURL + "vimeo-comentario-set-playable/" + anexoId;
        return this.tokenRequest(url,false);
    }

    vimeoGetUrlUpload(fileSize): Promise<any> {   
        if (this.Connectivity.isOnline()) {    
            const headersAuth = new HttpHeaders({
                "Authorization":	'bearer ' +  this.VIMEO_ACCESS,
                "Content-Type": 	'application/json',
                "Accept":	        'application/vnd.vimeo.*+json;version=3.4'
            });

            const path = "https://api.vimeo.com/me/videos";
            const body = {
                "upload": {
                  "approach": "tus",
                  "size": fileSize
                }
            };

            return this.http.post(path, body, {headers: headersAuth})
                .toPromise()
                .then(response => {
                    return response;
                })
                .catch(error => {
                    
                });
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
    }

    vimeoCheckStatus(videoId):Promise<any> {
        if (this.Connectivity.isOnline()) {    
            const headersAuth = new HttpHeaders({
                "Authorization":	'bearer ' +  this.VIMEO_ACCESS,
                "Content-Type": 	'application/json',
                "Accept":	        'application/vnd.vimeo.*+json;version=3.4'
            });

            const path = "https://api.vimeo.com/videos/" + videoId + "?fields=transcode.status"
            
            return this.http.get(path, {headers: headersAuth})
                .toPromise()
                .then(response => {
                    return response;
                })
                .catch(error => {
                    
                });
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
    }

    enviaPass(password) {
        const datos = { pass: password };
        const url = this.ApiURL + "new-password";
        return this.tokenRequestPost(url, datos);
    }    

    borrarCuentaUsuario() {
        const url = this.ApiURL + "usuario-borra-cuenta";
        return this.tokenRequest(url);
    }

    // common
    noTokenRequest(path: string, loader: boolean = true): Promise<any> {
        if (loader) this.messagePresenter.presentLoadingCustom('', '', 1000);
        if (this.Connectivity.isOnline()) {
            
            return this.http.get(path, { headers: this.headers })
                .toPromise()
                .then(response => {
                    //if (loader) this.messagePresenter.dismissLoadingCustom();
                    return response;
                })
                .catch(error => {
                    //if (loader) this.messagePresenter.dismissLoadingCustom();
                    return Promise.reject(error);
                });
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
    }

    noTokenRequestPost(datos: any, path: string, loader:boolean=true): Promise<any> {
        if (loader) this.messagePresenter.presentLoadingCustom('', '', 1000);
        if (this.Connectivity.isOnline()) {
            return this.http.post(path, datos, { headers: this.headers })
                .toPromise()
                .then(response => {
                    //if (loader) this.messagePresenter.dismissLoadingCustom();
                    return response;
                })
                .catch(error => {
                    //if (loader) this.messagePresenter.dismissLoadingCustom();
                    return Promise.reject(error);
                });
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
    }

    tokenRequest(path: string, loader:boolean=true): Promise<any> {
        if (loader) this.messagePresenter.presentLoadingCustom('', '', 1000);
        return this.storage.get('token').then(token => {
        if (this.Connectivity.isOnline()) {
            
            if (token) {
                let headersAuth = new HttpHeaders({
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                    });

                return this.http.get(path, {headers: headersAuth})
                    .toPromise()
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        if(!error.ok) {
                            this.goToLogin();
                        }
                    });
            } else {
                this.goToLogin();
            }
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
        })
    }

    tokenDelete(path: string, loader:boolean=true): Promise<any> {
        if (loader) this.messagePresenter.presentLoadingCustom('', '', 1000);
        return this.storage.get('token').then(token => {
        if (this.Connectivity.isOnline()) {
            if (token) {
            let headersAuth = new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
                });

            return this.http.delete(path, {headers: headersAuth})
                .toPromise()
                .then(response => {
                return response;
                })
                .catch(error => {
                    if(!error.ok) this.goToLogin();
                });
            }else{
                this.goToLogin();
            }
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
        })
    }

    noTokenDelete(path: string, loader:boolean=true): Promise<any> {
        if (loader) this.messagePresenter.presentLoadingCustom('', '', 1000);
        if (this.Connectivity.isOnline()) {
            return this.http.delete(path)
                .toPromise()
                .then(response => {
                return response;
                })
                .catch(error => {
                
                });
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
    }

    tokenRequestPost(path: string, body: any): Promise<any> {
        return this.storage.get('token').then(token => {
            
        if (this.Connectivity.isOnline()) {
            if (token) {
            let headersAuth = new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
                });

            return this.http.post(path, body, {headers: headersAuth})
                .toPromise()
                .then(response => {
                    return response;
                })
                .catch(error => {
                    if(!error.ok) {
                        
                        this.goToLogin();
                    }
                });
            }else{
                this.goToLogin();
            }
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
        
        });
    }

    tokenRequestPut(path: string, body: any): Promise<any> {
        return this.storage.get('token').then(token => {
        if (this.Connectivity.isOnline()) {
            if (token) {
            let headersAuth = new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
                });

            return this.http.put(path, body, {headers: headersAuth})
                .toPromise()
                .then(response => {
                return response;
                })
                .catch(error => {
                    if(!error.ok) this.goToLogin();
                });
            } else {
                this.goToLogin();
            }
        } else {
            this.messagePresenter.presentToast('Sin conexión',2000);
            return Promise.reject({Error: "noconn"});
        }
        })
    }

    goToLogin() {
       this.storage.clearkeys().then(
            () => { 
                console.log("Expulsión a login");
                this.router.navigateByUrl('/login');
            }
       );       
    }
    
    async resetPassword(datosForm) {
        const datos = { email: datosForm.emailReset };
        const url = this.ApiURL + "reset-password";
        return this.noTokenRequestPost(datos, url);
    }

    async openPdf(url) {
        Browser.open({url});
    }
   
    openLink(url) {
        
        Browser.open({url});
    }

    getDataFromHash(hash) {
        const url = this.ApiURL + "post-hash/" + hash;
        return this.tokenRequest(url,false);
    }

    getParticipationDataFromHash(hash) {
        //http://localhost:8100/tabs/contest/728984002651e692a20e94-885530172651e692a20e98
        const url = this.ApiURL + "participation-hash/" + hash;
        return this.tokenRequest(url,false);
    }

    checkSePuedeVotar(concursoId) {
        const url = this.ApiURL + "concurso-votable/" + concursoId;
        return this.tokenRequest(url, false);
    }
}