import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DEV,NODE_URL } from 'src/inc.global';

@Injectable({
  	providedIn: 'root'
})

export class AwsService {
	apiUrl = null;

	constructor(public http:HttpClient) { 
        this.apiUrl = NODE_URL;
	}

	getSignedUploadRequest(name, type) {
		return this.http.get(`${this.apiUrl}/aws/sign?file-name=${name}&file-type=${type}`).toPromise();
	}
	
	

	getSignedFileRequest(name) {
		return this.http.get(`${this.apiUrl}/aws/files/${name}`).toPromise();
	}

	deleteFile(name) {
		return this.http.delete(`${this.apiUrl}/aws/files/${name}`).toPromise();
	}

	uploadFile(url, file) {
		return this.http.put(url, file).toPromise();
	}

	randomString(chars) {
		let text = "";
		let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i=0; i<chars; i++) {
			text += possible.charAt(Math.floor(Math.random()*possible.length));
		}
		return text;
	}
}