import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { LocalStorageService}    	from '../providers/localStorage.service';
import { map, tap, switchMap }		from 'rxjs/operators';

const TOKEN_KEY = "token";
import { USER_KEY, LOGIN_URL  }                 from '../inc.global';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
	isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	token = '';

  	constructor(private storage:LocalStorageService, private http:HttpClient) { 
		this.loadToken();
	}

	async loadToken() {
		const token = await this.storage.get(TOKEN_KEY);
		if (token) {
			this.token = token;
			this.isAuthenticated.next(true);
		} else {
			this.isAuthenticated.next(false);
		}
	}

	login(credentials: {email,password}): Observable<any> {
        let url = LOGIN_URL;
        
        const login = this.http.post(url, credentials);
        return this.http.post(url, credentials).pipe(
            map((data:any)=>data),
            switchMap(data=>{
                this.storage.remove(USER_KEY);
                this.storage.remove(TOKEN_KEY);
                this.storage.set(USER_KEY, data.usuario);
                this.storage.set("ERROR_LOGIN", data.message);
                return from(this.storage.set(TOKEN_KEY, data.token));
            }),
            tap(_=> {
                this.isAuthenticated.next(true);
            })
        );
	}

	logout():Promise<void> {
		this.isAuthenticated.next(false);
		this.storage.remove(USER_KEY);
		return this.storage.remove(TOKEN_KEY);
	}
}
