<div class="Post">
    <div class="PostHeader">
        <ion-list lines="none">
            <h5 *ngIf="Post?.DatosEspecie">{{'PORQUE_LE_INTERESA'|translate}} {{Post.DatosEspecie?.nombre_es}}</h5>
            <h5 *ngIf="Post?.DatosCargo">{{'PORQUE_LE_INTERESA'|translate}} {{Post.DatosCargo?.nombre_es}}</h5>
            <h5 *ngIf="Post?.Interes">{{'PODRIA_INTERESARLE'|translate}}</h5>
            <ion-item class="rt" *ngIf="Post?.UsuarioId!=DatosDestinatario?.Personales?.id && Seccion=='perfil'">
                <ion-icon name="repeat-outline" ></ion-icon>&nbsp;&nbsp;{{DatosDestinatario?.Personales?.nombre}} {{'MURO_HA_REPOSTEADO'|translate}}
            </ion-item>
            <ion-item detail="false" (click)="abreMuro(Post)">
                <ion-avatar slot="start" >
                    <img *ngIf="Post?.UsuarioFoto" [src]="AmazonS3Url + Post?.UsuarioFoto" />
                    <img *ngIf="!Post?.UsuarioFoto" src="../assets/imgs/user_default.png" class="AvatarFade"/>
                </ion-avatar>
                <ion-label class="ion-text-wrap">
                    <div class="DebugText" *ngIf="Post?.Debug && ModoDebug">{{Post.Debug}}</div>
                    <h2>{{Post?.UsuarioNombre}}</h2>
                    <small>{{Post?.ActividadNombre}} &bull; {{Post?.created_at | fechaFormateada}}</small>
                </ion-label>
                <ion-button slot="end" color="dark" fill="clear" size="small" *ngIf="DatosUsuario.id==Post?.UsuarioId" (click)="muestraPopover($event)"><ion-icon slot="icon-only" name="ellipsis-horizontal-outline"></ion-icon></ion-button>
            </ion-item>
            
        </ion-list>
    </div>

    <div class="PostDescripcion" tappable (click)="abreComentarios(Post)" *ngIf="Post?.PostDescripcion">
        <small *ngIf="Post?.PostEditado">{{'GENERAL_EDITADO'|translate}}</small>
        <div [hidden]="VerEntero || Seccion=='Comentarios'" [innerHtml]="Post?.PostDescripcion | truncate:170 | ParseHtml" dynamicContent></div>
        <div [hidden]="!VerEntero && Seccion!='Comentarios'" [innerHtml]="Post?.PostDescripcion | ParseHtml" dynamicContent></div>
        
        <div style="width:100%; margin-top:15px" *ngIf="EnlacesTexto">
            <div *ngFor="let enlace of EnlacesTexto">
                
                <div *ngIf="enlace.tipo=='play-outline'"  class="container" (click)="openLink(enlace.enlace)">
                    <img [src]="dameImagenVideo(enlace)" width="100%"/>
                    <div class="btn"><ion-icon name="play-outline" class="BigIcon" color="primary"></ion-icon></div>
                </div>
            </div>
        </div>
        
        <div *ngIf="Post?.UsuariosNombrados?.length>0">
            
            <div *ngFor="let usuario of Post?.UsuariosNombrados" tappable (click)="abreMuroUsuario(usuario.id)">
                <img [src]="AmazonS3Url + usuario.foto" *ngIf="usuario.foto" title="{{usuario.nombre}}" class="Mencionado" />
                <img *ngIf="!usuario.foto" src="assets/imgs/user_default.png" title="{{usuario.nombre}}" class="Mencionado"/>
            </div>
            <div style="clear: both;">&nbsp;</div>
        </div>
    </div>

    <div class="PostAnexos" *ngIf="(Post?.AnexosImg?.length>0 || Post?.AnexosPdf?.length>0 || Post?.AnexosVideo?.length>0) && Hook!='side'">
        <div *ngIf="Post?.AnexosImg?.length>0">
            <div *ngFor="let anexo of Post.AnexosImg; let i = index" tappable (click)="abreVisor($event, Post.AnexosUrls, i)">
                <ion-img src="/assets/imgs/pixel.png"   [style.background-image]="'url('+ AmazonS3Url + anexo.url +')'" [style.background-size]="'cover'" [style.background-repeat]="'no-repeat'" class="AnexoSizeNormal" *ngIf="i==0"></ion-img>
                <ion-img src="assets/imgs/pixel.png"    [style.background-image]="'url('+ AmazonS3Url + anexo.url +')'" [style.background-size]="'cover'" [style.background-repeat]="'no-repeat'" class="AnexoSizeMini" *ngIf="i>0"></ion-img>
            </div>
            <div style="clear: both;"></div>
        </div>
        
        
        <div *ngIf="AnexosVideoParseados && AnexosVideoParseados.length>0">
            <div *ngFor="let anexo of AnexosVideoParseados" class="ion-margin-top">
                <div class='embed-container' *ngIf="anexo[0]"><iframe *ngIf="anexo[0]" [src]="anexo[1]" frameborder="0" allowfullscreen></iframe></div>
                <div *ngIf="!anexo[0]" class="container">
                    <img src="assets/imgs/placeholder-16x9.jpg" style="opacity: 0.5;"/>
                    <div class="centered" >
                        <h3 class="ion-margin-top">{{'GENERAL_VIDEO_PROCESANDO'|translate}}</h3>
                        <ion-button fill="outline" size="large" mode="ios" color="dark" (click)="recargaVideo(anexo)" *ngIf="!Recargando">
                            <ion-icon name="refresh-outline" class="ion-refreshing" slot="start"></ion-icon>
                            <ion-label>{{'GENERAL_RECARGAR'|translate}}</ion-label>
                        </ion-button>
                        <ion-button fill="outline" size="large" mode="ios" color="dark"  *ngIf="Recargando">
                            <ion-spinner name="circular" *ngIf="Recargando" color="dark" class="ion-margin-end"></ion-spinner>
                            {{'GENERAL_COMPROBANDO'|translate}}
                        </ion-button>
                    </div>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
        

        <div class="BloquePostAdjuntos" *ngIf="Post?.AnexosPdf?.length>0">
            <small>{{'ADJUNTOS_EN_PDF'|translate}}</small><br><br>
            <ion-button color="primary" mode="ios" *ngFor="let anexo of Post.AnexosPdf" (click)="openPdf($event, anexo)">
                <ion-icon name="document-outline" slot="start"></ion-icon>
                PDF {{anexo.titulo|truncate:30}}
            </ion-button>			
        </div>

        
    </div>

    <div class="PostFooter">
                    
        <ion-grid>
            <ion-row>
                
                <ion-col offset="1" size="2" *ngIf="Hook!='side'">
                    <ion-button mode="ios" color="dark" fill="clear" (click)="abreComentarios(Post)" size="small">
                        <ion-icon  slot="start" name="chatbubble-outline"></ion-icon>
                        <ion-label *ngIf="Hook!='side'"><span *ngIf="Post?.comentarios_count>0">{{Post?.comentarios_count}}</span></ion-label>
                    </ion-button>                    
                </ion-col>
                <ion-col size="2">
                    <ion-button mode="ios" color="primary" fill="clear" (click)="changeStatusLike(1)" *ngIf="Post?.like_mio" size="small">
                        <ion-icon name="heart-outline" slot="start"></ion-icon>
                        <ion-label *ngIf="Hook!='side'"><span *ngIf="Post?.likes_totales>0">{{Post?.likes_totales}}</span></ion-label>
                    </ion-button>
                    <ion-button mode="ios" color="dark" fill="clear" (click)="changeStatusLike(0)" *ngIf="!Post?.like_mio" size="small">
                        <ion-icon name="heart-outline" slot="start"></ion-icon>
                        <ion-label *ngIf="Hook!='side'"><span *ngIf="Post?.likes_totales>0">{{Post?.likes_totales}}</span></ion-label>
                    </ion-button>
                </ion-col>
                <ion-col size="2">
                    <ion-button mode="ios" color="dark"     fill="clear"  *ngIf="DatosUsuario.id==Post?.UsuarioId" disabled size="small"><ion-icon slot="start" name="repeat-outline" ></ion-icon><span *ngIf="Post?.recomendados_totales>0">{{Post?.recomendados_totales}}</span></ion-button>
                    <ion-button mode="ios" color="primary"  fill="clear" (click)="changeStatusRecomendado(1)" *ngIf="Post?.recomendado_mio" size="small">
                        <ion-icon slot="start" name="repeat-outline"></ion-icon>
                        <ion-label *ngIf="Hook!='side'"><span *ngIf="Post?.recomendados_totales>0">{{Post?.recomendados_totales}}</span></ion-label>
                        
                    </ion-button>
                    <ion-button mode="ios" color="dark"     fill="clear" (click)="changeStatusRecomendado(0)" *ngIf="!Post?.recomendado_mio && DatosUsuario.id!=Post?.UsuarioId" size="small">
                        <ion-icon slot="start" name="repeat-outline"></ion-icon>
                        <ion-label *ngIf="Hook!='side'"><span *ngIf="Post?.recomendados_totales>0">{{Post?.recomendados_totales}}</span></ion-label>
                    </ion-button>
                </ion-col>
                <ion-col size="2" *ngIf="Hook!='side'">
                    <ion-button mode="ios" color="dark" fill="clear" (click)="muestraPopoverCompartir($event)" size="small">
                        <ion-icon slot="start" name="share-outline"></ion-icon>
                    </ion-button>
                </ion-col>
                <ion-col size="2">
                    <ion-button mode="ios" color="primary" fill="clear" (click)="changeStatusGuardado(1)" *ngIf="Post?.guardado_mio" size="small">
                        <ion-icon name="bookmark-outline" slot="start"></ion-icon>
                        <ion-label *ngIf="Hook=='side'"><span *ngIf="Post?.guardados_totales>0">{{Post?.guardados_totales}}</span></ion-label>
                    </ion-button>
                    <ion-button mode="ios" color="dark" fill="clear" (click)="changeStatusGuardado(0)" *ngIf="!Post?.guardado_mio" size="small">
                        <ion-icon name="bookmark-outline" slot="start"></ion-icon>
                        <ion-label *ngIf="Hook=='side'"><span *ngIf="Post?.guardados_totales>0">{{Post?.guardados_totales}}</span></ion-label>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>

    <div class="PostComentarios"  *ngIf="Post?.Comentarios && Post?.Comentarios.length>0 && Seccion!='Comentarios' && Hook!='side'"  >
        <ion-list lines="none" style="margin-top:0px !important">
            <!--<ion-item><ion-label style="font-size:14px">Últimos comentarios</ion-label></ion-item>-->
			<ion-item detail="false" *ngFor="let Comentario of Post.Comentarios" style="padding-left:10px !important; margin-bottom:5px !important;">
                
				<ion-avatar tappable (click)="abreMuroUsuario(Comentario.UsuarioId)" slot="start" style="margin: 0 8px 8px 0 !important; margin-bottom: auto !important; width:32px !important; height:32px !important">
					<img *ngIf="Comentario.UsuarioFoto" [src]="AmazonS3Url + Comentario.UsuarioFoto" />
					<img *ngIf="!Comentario.UsuarioFoto" src="../assets/imgs/user_default.png" />
				</ion-avatar>
								
				<div class="ComentarioDescripcionBloque" tappable (click)="abreComentarios(Post)">
					<h4>{{Comentario.UsuarioNombre}}</h4>
					<div class="ComentarioDescripcion" *ngIf="Comentario.ComentarioDescripcion" [innerHTML]="Comentario.ComentarioDescripcion | truncate:60"></div>

                    <div *ngIf="Comentario.AnexosImg?.length>0 || Comentario.AnexosPdf?.length>0 || Comentario.ComentariosAnexosVideoParseados?.length>0"  class="ion-padding">
                        <div *ngIf="Comentario.AnexosImg?.length>0" >
                            <div *ngFor="let anexo of Comentario.AnexosImg; let i=index">
                                <img (click)="abreVisor($event, anexo.url, i)" [src]="AmazonS3Url + anexo.url" class="AnexoMini" />
                            </div>
                        </div>
                        
                        <div *ngIf="Comentario.ComentariosAnexosVideoParseados && Comentario.ComentariosAnexosVideoParseados.length>0">
                            <div *ngFor="let anexo of Comentario.ComentariosAnexosVideoParseados" class="ion-margin-top">
                                <div class='embed-container' *ngIf="anexo[0]"><iframe *ngIf="anexo[0]" [src]="anexo[1]" frameborder="0" allowfullscreen></iframe></div>
                                <div *ngIf="!anexo[0]" class="container">
                                    <img src="assets/imgs/placeholder-16x9.jpg" style="opacity: 0.5;"/>
                                    <div class="centered" >
                                        <h3 class="ion-margin-top mini">{{'GENERAL_VIDEO_PROCESANDO'|translate}}</h3>
                                        <ion-button fill="outline" size="large" mode="ios" color="dark" (click)="recargaVideo(anexo, $event)" *ngIf="!Recargando">
                                            <ion-icon name="refresh-outline" class="ion-refreshing" slot="start"></ion-icon>
                                            <ion-label>{{'GENERAL_RECARGAR'|translate}}</ion-label>
                                        </ion-button>
                                        <ion-button fill="outline" size="large" mode="ios" color="dark"  *ngIf="Recargando">
                                            <ion-spinner name="circular" *ngIf="Recargando" color="dark" class="ion-margin-end"></ion-spinner>
                                            {{'GENERAL_COMPROBANDO'|translate}}
                                        </ion-button>
                                    </div>
                                </div>
                            </div>
                            <div style="clear: both;"></div>
                        </div>

                        <div *ngIf="Comentario.AnexosPdf?.length>0">
                            <ion-button size="small" color="primary" mode="ios" *ngFor="let anexo of Comentario.AnexosPdf" (click)="openPdf($event, anexo)">
                                <ion-icon name="document-outline" slot="start"></ion-icon>
                                PDF {{anexo.titulo|truncate:30}}
                            </ion-button>			
                        </div>

                    </div>
    
                    <div *ngIf="Comentario.UsuariosNombrados?.length>0" style="margin:4px 0 0 0 !important">
                        <div *ngFor="let usuario of Comentario?.UsuariosNombrados" tappable (click)="abreMuroUsuario(usuario.id)">
                            <img [src]="AmazonS3Url + usuario.foto" *ngIf="usuario.foto" title="{{usuario.nombre}}" class="Mencionado" />
                            <img *ngIf="!usuario.foto" src="assets/imgs/user_default.png" title="{{usuario.nombre}}" class="Mencionado"/>
                        </div>
                        <div style="clear: both; line-height: 1px;">&nbsp;</div>
                    </div>

					<p class="fecha ion-text-end" stlye="margin:0">{{Comentario.created_at | fechaFormateada}}</p>
                    
				</div>
                <ion-button *ngIf="DatosUsuario.id==Comentario.UsuarioId" (click)="borraComentario($event, Comentario)" color="danger" size="small" fill="clear" class="ion-float-end"><ion-icon slot="icon-only" name="trash-outline"></ion-icon></ion-button>
			</ion-item>
            
		</ion-list>
    </div>

</div>