import { DatePipe, registerLocaleData } from '@angular/common';
import { Pipe, PipeTransform, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import locale419 from '@angular/common/locales/es-419';

registerLocaleData(locale419, 'es');

@Pipe({
  name: 'localizedDate',
  pure: false
})

export class LocalizedDatePipe implements PipeTransform {

	constructor(private translateService: TranslateService) {
	}

	transform(value: any, pattern: string = 'mediumDate'): any {
		const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
		return datePipe.transform(value, pattern);
	}

}