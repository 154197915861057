import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { LocalStorageService } from 'src/providers/localStorage.service';

import { BIG_THUMB_SIZE, AMAZONS3_URL_RESIZE }             from '../../inc.global';

@Component({
  selector: 'app-fotoperfil',
  templateUrl: './fotoperfil.component.html',
  styleUrls: ['./fotoperfil.component.scss'],
})

export class FotoperfilComponent implements OnInit {

	UsuarioFoto :string = null;
	UsuarioId : number = null;

	Dimensiones = BIG_THUMB_SIZE;
    AmazonS3Url = AMAZONS3_URL_RESIZE + this.Dimensiones;

	constructor(private storage:LocalStorageService, 
				private router:Router,
				public menuCtrl:MenuController) {
		
		this.storage.get("usuario")
		.then((response)=>{ 
			if (response) {
				this.UsuarioId = response.id;
				this.UsuarioFoto = this.AmazonS3Url + response.foto; 
			} else {
				this.UsuarioFoto = "assets/imgs/user_default.png";
			}
		});
		
	}

	ngOnInit() {}

	abrePerfil() {
		const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: this.UsuarioId,
                prevPage: this.router.url
            }
        };
		this.router.navigate(['profile/'], navigationExtras);
	}
}