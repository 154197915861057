import { Component } 			from '@angular/core';
import { TranslateService } 	from '@ngx-translate/core';

import { Router, NavigationExtras, ActivatedRoute } 				from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { LocalStorageService }  from 'src/providers/localStorage.service';

import { UpdateService }            from 'src/services/update.service';
import { Device }                   from '@capacitor/device';
import { API }                      from 'src/providers/api';

import { VERSION_INSTALADA, USER_KEY, BIG_THUMB_SIZE, AMAZONS3_URL_RESIZE }             from '../inc.global';


@Component({
selector: 'app-root',
templateUrl: 'app.component.html',
styleUrls: ['app.component.scss'],
})

export class AppComponent {
    pwa : boolean = false;
	Configuraciones : any = [];
	selectedPath = "";
	DatosUsuario :any = null;
	FotoUsuario = null;
	Dimensiones = BIG_THUMB_SIZE;
    AmazonS3Url = AMAZONS3_URL_RESIZE + this.Dimensiones;
    VersionInstalada    = VERSION_INSTALADA;
    CurrentUrl = "";
    NumSiguiendo    : number = null;
    NumSeguidores   : number = null; 
    
    NotificacionesUnreadNumberMessages : number = 0;
    NotificacionesUnreadNumberEventos : number = 0;
    NotificacionesUnreadNumberConcursos : number = 0;
	
	constructor(private translate: TranslateService, 
                private api:API,
				private authService: AuthenticationService,
				private router:Router,
                private route:ActivatedRoute,
				private storage:LocalStorageService,
				private updateService:UpdateService,
				
                ) {

                this.saveDeviceInfo();
                
                this.cargaUsuario();
                                
                this.updateService.getLoginObservable().subscribe((data)=>{
                    this.cargaUsuario();
                });

                this.updateService.getFollowUsuario().subscribe((datos)=>{
                    this.cargaUsuario();
                });

                this.updateService.getPaginaCambiadaObservable().subscribe((datos)=>{
                    this.CurrentUrl = datos;  
                });

                document.addEventListener('contextmenu', event => {
                    event.preventDefault();
                });

	}

    ionViewDidLeave() {
        this.actualizaPaginaActual();
    }

    async abrirPerfil() {
        this.CurrentUrl = "";
        await this.router.navigateByUrl('tabs/profile');
		return;
    }
    
    async saveDeviceInfo() {
        const deviceInfo = await Device.getInfo();
        if (deviceInfo.platform=='web') this.pwa = true;
        this.storage.set("device", deviceInfo);
    }

    async getNotificacionesUnreadNumber() {
        
        this.NotificacionesUnreadNumberEventos  = await this.api.getNotificacionesUnreadNumberEventos();
        this.NotificacionesUnreadNumberMessages = await this.api.getNotificacionesUnreadNumberMessages();
        this.NotificacionesUnreadNumberConcursos = await this.api.getNotificacionesUnreadNumberConcursos();
    }

	cargaUsuario() {
        
		this.storage.get(USER_KEY)
		.then((response)=>{ 
			if (response) {
                this.getNotificacionesUnreadNumber();

				this.DatosUsuario = response;
                this.translate.use(this.DatosUsuario.locale.toLowerCase());
                
				if (this.DatosUsuario.foto) this.FotoUsuario = this.AmazonS3Url + this.DatosUsuario.foto; 
                this.api.getPerfil(response.id).then((datos)=>{
                    this.NumSeguidores  = datos.TotalSeguidores;
                    this.NumSiguiendo   = datos.TotalSeguidos;
                });
                
			} else {
				this.FotoUsuario = "assets/imgs/user_default.png";
			}
		});
	}

	CerrarSesion() {
		this.CurrentUrl = "";
		this.authService.logout();
		this.router.navigateByUrl('login');
	}
	
    async abreInicio() {
        await this.router.navigateByUrl('tabs/feed');
        this.actualizaPaginaActual();
        return;
    }

    async abreMuro(UsuarioId) {
        const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: UsuarioId,
                prevPage: this.router.url 
            }
        };
		await this.router.navigate(['my-wall/'], navigationExtras);
		this.actualizaPaginaActual();
		return;
	}

    

    async abreEventos() {
        if (this.NotificacionesUnreadNumberEventos>0) {
            this.NotificacionesUnreadNumberEventos = 0;
            this.api.marcaNotificacionesLeidas('eventos');
        }
        
		await this.router.navigateByUrl('tabs/courses');
        this.actualizaPaginaActual();
		return;
	}

    async abreCv() {
		await this.router.navigateByUrl('tabs/employ/cv');
        this.actualizaPaginaActual();
		return;
	}

    async abreBuscar() {
		await this.router.navigateByUrl('tabs/explore');
        this.actualizaPaginaActual();
		return;
	}

    async abreMensajes() {
        if (this.NotificacionesUnreadNumberMessages>0) {
            this.NotificacionesUnreadNumberMessages = 0;
            this.api.marcaNotificacionesLeidas('mensajes');
        }
        
		await this.router.navigateByUrl('tabs/messages');
        this.actualizaPaginaActual();
		return;
	}

    async abreConcursos() {
        if (this.NotificacionesUnreadNumberConcursos>0) {
            this.NotificacionesUnreadNumberConcursos = 0;
            this.api.marcaNotificacionesLeidas('concursos');
        }
        
		await this.router.navigateByUrl('tabs/contests');
        this.actualizaPaginaActual();
		return;
	}

    async abreGuardados() {
        await this.router.navigateByUrl('tabs/saved');
        this.actualizaPaginaActual();
        return;
    }

    async abreMisEmpleos() {
        await this.router.navigateByUrl('jobs-own');
        this.actualizaPaginaActual();
        return;
    }

    async abrePerfil() {
        await this.router.navigateByUrl('tabs/profile');
        this.actualizaPaginaActual();
		return;
    }

    async abreEmpleo() {
        await this.router.navigateByUrl('tabs/employ');
        this.actualizaPaginaActual();
		return;
    }

    async abreAnuncios() {
        await this.router.navigateByUrl('tabs/ads');
        this.actualizaPaginaActual();
		return;
    }

    async abrePublicar() {
        await this.router.navigateByUrl('tabs/post-new');
        this.actualizaPaginaActual();
		return;
    }

    async abreTest() {
        await this.router.navigateByUrl('tabs/test');
        this.actualizaPaginaActual();
		return;
    }

   async abreSeguidores() {
        const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: this.DatosUsuario.id,
                prevPage: this.router.url 
            }
        };
		await this.router.navigate(['followers/'], navigationExtras);
		this.actualizaPaginaActual();
		return;
    }

    async abreSeguidos() {
        const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: this.DatosUsuario.id,
                prevPage: this.router.url 
            }
        };
		await this.router.navigate(['following/'], navigationExtras);
		this.actualizaPaginaActual();
		return;
    }

    async abreContacto() {
        await this.router.navigateByUrl('tabs/contact');
        this.actualizaPaginaActual();
		return;
    }

    actualizaPaginaActual() {
        let tope = this.router.url.indexOf(';');
        
        if (tope==-1) {
            this.CurrentUrl   = this.router.url;
        } else {
            this.CurrentUrl   = this.router.url.slice(0, tope);
        }
        //console.log(this.CurrentUrl);
        
    }

    versionInstalada() {
        let retorno = "";
        const version : string = VERSION_INSTALADA.toString();
        for (let i = 0; i < version.length; i++) {
            retorno += version.charAt(i) + ".";
          }
        return retorno;
    }
}
