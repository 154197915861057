import { Injectable } 		from '@angular/core';
import { CanActivate, Router } 	from '@angular/router';
import { API }  from '../providers/api';

import { VERSION_INSTALADA } from '../inc.global';

@Injectable({
  	providedIn: 'root'
})

export class VersionGuard implements CanActivate {

	constructor (private router:Router, private api:API) {

	}

	async canActivate(): Promise<boolean> {
		return this.api.getConfiguraciones().then((response)=>{
			
			
			if (VERSION_INSTALADA < parseInt(response.version_minima)) {
				this.router.navigateByUrl('update');
				return false;
			} else {
				
				return true;
			}
			
		});
	}
    
  
}