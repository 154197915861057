import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { VersionGuard }   from '../guards/version.guard';
import { AuthGuard }      from '../guards/auth.guard';
import { AutoLoginGuard } from '../guards/auto-login.guard';

const routes: Routes = [
    {
        path: 'update',
        loadChildren: () => import('./extra-pages/actualizar/actualizar.module').then( m => m.ActualizarPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./usuario-pages/registro/registro.module').then( m => m.RegistroPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./usuario-pages/login/login.module').then( m => m.LoginPageModule),
        canLoad: [AutoLoginGuard]
    },
    {
        path: 'post/:hash',
        loadChildren: () => import('./posts-pages/comentarios/comentarios.module').then( m => m.ComentariosPageModule),
        canLoad: [AuthGuard],
        canActivate: [VersionGuard]
    },  
    {
        path: 'tabs',
        loadChildren: () => import('./secciones-pages/tabs/tabs.module').then( m => m.TabsPageModule),
        canLoad: [AuthGuard],
        canActivate: [VersionGuard]
    },
    {
        path: 'wall',
        loadChildren: () => import('./social-pages/muro/muro.module').then( m => m.MuroPageModule),
        canLoad: [AuthGuard],
        canActivate: [VersionGuard]
    },
    {
        path: 'my-wall',
        loadChildren: () => import('./social-pages/mi-muro/mi-muro.module').then( m => m.MiMuroPageModule),
        canLoad: [AuthGuard],
        canActivate: [VersionGuard]
    },
  {
    path: 'featured',
    loadChildren: () => import('./posts-pages/destacados/destacados.module').then( m => m.DestacadosPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'ad-contact',
    loadChildren: () => import('./anuncios-pages/anuncio-contactar/anuncio-contactar.module').then( m => m.AnuncioContactarPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'ad-post',
    loadChildren: () => import('./anuncios-pages/anuncio-publicar/anuncio-publicar.module').then( m => m.AnuncioPublicarPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'ad-edit',
    loadChildren: () => import('./anuncios-pages/anuncio-editar/anuncio-editar.module').then( m => m.AnuncioEditarPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'ad-requested',
    loadChildren: () => import('./anuncios-pages/anuncio-interesados/anuncio-interesados.module').then( m => m.AnuncioInteresadosPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'chats',
    loadChildren: () => import('./chat-pages/chats/chats.module').then( m => m.ChatsPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'chat-detail',
    loadChildren: () => import('./chat-pages/chat-detalle/chat-detalle.module').then( m => m.ChatDetallePageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'chat-new',
    loadChildren: () => import('./chat-pages/chat-new/chat-new.module').then( m => m.ChatNewPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'post-edit',
    loadChildren: () => import('./posts-pages/post-edit/post-edit.module').then( m => m.PostEditPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'viewer',
    loadChildren: () => import('./extra-pages/visor/visor.module').then( m => m.VisorPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./usuario-pages/perfil/perfil.module').then( m => m.PerfilPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'following',
    loadChildren: () => import('./social-pages/seguidos/seguidos.module').then( m => m.SeguidosPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'followers',
    loadChildren: () => import('./social-pages/seguidores/seguidores.module').then( m => m.SeguidoresPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'chat-info',
    loadChildren: () => import('./chat-pages/chat-info/chat-info.module').then( m => m.ChatInfoPageModule),
    canLoad: [AuthGuard],
    canActivate: [VersionGuard]
  },
  {
    path: 'cv-experiencia',
    loadChildren: () => import('./cv-pages/cv-experiencia/cv-experiencia.module').then( m => m.CvExperienciaPageModule)
  },
  {
    path: 'cv-estudio',
    loadChildren: () => import('./cv-pages/cv-estudio/cv-estudio.module').then( m => m.CvEstudioPageModule)
  },
  {
    path: 'cv-curso',
    loadChildren: () => import('./cv-pages/cv-curso/cv-curso.module').then( m => m.CvCursoPageModule)
  },
  {
    path: 'cv-publicacion',
    loadChildren: () => import('./cv-pages/cv-publicacion/cv-publicacion.module').then( m => m.CvPublicacionPageModule)
  },
  {
    path: 'cv-informatica',
    loadChildren: () => import('./cv-pages/cv-informatica/cv-informatica.module').then( m => m.CvInformaticaPageModule)
  },
  {
    path: 'cv-merito',
    loadChildren: () => import('./cv-pages/cv-merito/cv-merito.module').then( m => m.CvMeritoPageModule)
  },
  {
    path: 'cv-idioma',
    loadChildren: () => import('./cv-pages/cv-idioma/cv-idioma.module').then( m => m.CvIdiomaPageModule)
  },
  {
    path: 'cv-asociacion',
    loadChildren: () => import('./cv-pages/cv-asociacion/cv-asociacion.module').then( m => m.CvAsociacionPageModule)
  },
  {
    path: 'cv-otros',
    loadChildren: () => import('./cv-pages/cv-otros/cv-otros.module').then( m => m.CvOtrosPageModule)
  },
  {
    path: 'cv-preferencias',
    loadChildren: () => import('./cv-pages/cv-preferencias/cv-preferencias.module').then( m => m.CvPreferenciasPageModule)
  },
  {
    path: 'cv-habilidades',
    loadChildren: () => import('./cv-pages/cv-habilidades/cv-habilidades.module').then( m => m.CvHabilidadesPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./empleo-pages/empresa/empresa.module').then( m => m.EmpresaPageModule)
  },
  {
    path: 'respuestas',
    loadChildren: () => import('./empleo-pages/respuestas/respuestas.module').then( m => m.RespuestasPageModule)
  },
  
  {
        path: 'votacion-detalle',
        loadChildren: () => import('./concursos-pages/votacion-detalle/votacion-detalle.module').then( m => m.VotacionDetallePageModule)
  },
    { 
        path: 'redirect',
        loadChildren: () => import('./extra-pages/redireccion/redireccion.module').then( m => m.RedireccionPageModule)
    },
    {
        path: '',
        loadChildren: () => import('./usuario-pages/login/login.module').then( m => m.LoginPageModule),
        canLoad: [AutoLoginGuard]
    },
    {
        path: '**',
        loadChildren: () => import('./social-pages/feed/feed.module').then( m => m.FeedPageModule),
    }

    
  
];
// http://localhost:8100/post/159054591365437db812b91-19443995565437db812b94

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
