import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } 			from '@angular/core';
import { CommonModule } 		from '@angular/common';

import { TruncatePipe } 		    from '../pipes/truncate.pipe';
import { FechaFormateadaPipe }      from '../pipes/fecha-formateada.pipe';
import { ParseHtmlPipe }            from '../pipes/parseHtml';
import { LocalizedDatePipe }        from '../pipes/localized-date.pipe';

import { PlaceholderComponent }     from './placeholder/placeholder.component';
import { FotoperfilComponent } 	    from './fotoperfil/fotoperfil.component';
import { BloqueSeguirComponent }    from './bloque-seguir/bloque-seguir.component';
import { BloqueRecomendadosComponent } from './bloque-recomendados/bloque-recomendados.component';
import { BloqueConcursoComponent }   from './bloque-concurso/bloque-concurso.component';
import { PostComponent } 		    from './post/post.component';

import { PopoverAnuncioComponent }      from './popover-anuncio/popover-anuncio.component';
import { PopoverPostComponent }         from './popover-post/popover-post.component'; 
import { PopoverInscritoComponent }     from './popover-inscrito/popover-inscrito.component'; 
import { PopoverOfertaComponent }       from './popover-oferta/popover-oferta.component'; 
import { PopoverCompartirPostComponent }    from './popover-compartir-post/popover-compartir-post.component'; 
import { PopoverCompartirParticipacionComponent }    from './popover-compartir-participacion/popover-compartir-participacion.component'; 
import { PopoverCompartirConcursoComponent }    from './popover-compartir-concurso/popover-compartir-concurso.component'; 
import { PopoverCompartirEventoComponent }      from './popover-compartir-evento/popover-compartir-evento.component'; 
import { PopoverCompartirAnuncioComponent }      from './popover-compartir-anuncio/popover-compartir-anuncio.component'; 
import { PopoverCompartirEmpleoComponent }      from './popover-compartir-empleo/popover-compartir-empleo.component'; 

import { SwiperModule } 			from 'swiper/angular';

import { DirectivesModule }         from '../directives/directives.module';
import { TranslateModule }          from '@ngx-translate/core';
import { FormsModule }              from '@angular/forms';

@NgModule({
    imports:          [CommonModule, SwiperModule, DirectivesModule, TranslateModule, FormsModule],
    declarations:     [LocalizedDatePipe, TruncatePipe, ParseHtmlPipe, FechaFormateadaPipe, PlaceholderComponent, FotoperfilComponent, PostComponent, BloqueSeguirComponent, BloqueRecomendadosComponent, BloqueConcursoComponent, PopoverAnuncioComponent, PopoverPostComponent, PopoverInscritoComponent, PopoverOfertaComponent, PopoverCompartirPostComponent, PopoverCompartirParticipacionComponent, PopoverCompartirConcursoComponent, PopoverCompartirEventoComponent, PopoverCompartirAnuncioComponent, PopoverCompartirEmpleoComponent],
    exports:          [LocalizedDatePipe, TruncatePipe, ParseHtmlPipe, FechaFormateadaPipe, PlaceholderComponent, FotoperfilComponent, PostComponent, BloqueSeguirComponent, BloqueRecomendadosComponent, BloqueConcursoComponent, PopoverAnuncioComponent, PopoverPostComponent, PopoverInscritoComponent, PopoverOfertaComponent, PopoverCompartirPostComponent, PopoverCompartirParticipacionComponent, PopoverCompartirConcursoComponent, PopoverCompartirEventoComponent, PopoverCompartirAnuncioComponent, PopoverCompartirEmpleoComponent, SwiperModule, DirectivesModule, TranslateModule ],
    entryComponents:  [PostComponent],
    schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
})

export class ComponentsModule {}