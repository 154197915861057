import { Injectable }       from '@angular/core';
import { Storage }          from '@ionic/storage-angular';


@Injectable()

export class LocalStorageService {  

    private _storage: Storage | null = null;

    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this._storage = storage;
    }

    get(key:string) {
        return this.storage.get(key);
    }
            
    set(key:string, value:any) {
        return this.storage?.set(key, value);
    }

    remove(key:string) {
        return this.storage?.remove(key);
    }

    clearkeys() {
        return this.storage.clear();
    }
    
}