import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { API } from 'src/providers/api';

@Component({
  selector: 'app-popover-post',
  templateUrl: './popover-post.component.html',
  styleUrls: ['./popover-post.component.scss'],
})

export class PopoverPostComponent implements OnInit {

	@Input() Post: any;

	constructor(private router:Router,
				private api:API,
				private popoverController:PopoverController,
				private alertCtrl: AlertController,
                private translate:TranslateService
				) { }

	ngOnInit() {}

	editarPost(post) {
		this.popoverController.dismiss(null,'editando');		
		const navigationExtras: NavigationExtras = {
            state: {
                PostId: post.PostId,
                prevPage: this.router.url
            }
        };
		this.router.navigate(['post-edit'], navigationExtras);
	}

	async borrarPost(post) {
		
		this.api.borraPost(post.PostId).then(
			() => {	
					this.popoverController.dismiss(null,'borrado');		
					this.presentAlertPublicacionEliminada();
				}
		);
	}

	async presentAlertPublicacionEliminada() {
		const Mensaje = this.translate.instant('GENERAL_PUBLICACION_ELIMINADA');
		
		const alert = await this.alertCtrl.create({
		  cssClass: 'my-custom-class',
		  message: Mensaje,
		  buttons: ['OK']
		});
	
		await alert.present();	
	}

	async cancelar() {
		await this.popoverController.dismiss(null,'cancelar');
	}

}
