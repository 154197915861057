<ion-header>
	<ion-toolbar>
		<ion-title class="ion-text-left">{{nombreUsuario}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()"><ion-icon slot="icon-only" name="close-outline"></ion-icon></ion-button>
        </ion-buttons>
	</ion-toolbar>
	
</ion-header>

<ion-content class="ion-padding">
	<ion-list>
		<ion-list-header>{{'EMPLEOS_RESPUESTA_CANDIDATO'|translate}}</ion-list-header>
		<ion-item *ngFor="let respuesta of Respuestas">
			<ion-icon name="help-circle-outline" slot="start"></ion-icon>
			<ion-label class="ion-text-wrap">
				<h2>{{respuesta.pregunta}}</h2>
				<p>{{respuesta.respuesta}}</p>
			</ion-label>
		</ion-item>
	</ion-list>
</ion-content>
