export const DEV : boolean          = false;
export const VERSION_INSTALADA      = 391;

export const TRADUCCIONES_URL       = DEV?"https://help.yosvet.com/api-dev/traducciones/"   :   "https://admin.yosvet.com/api/traducciones/"; 
export const API_URL                = DEV?"https://help.yosvet.com/api-dev/"                :   "https://admin.yosvet.com/api/";              
export const LOGIN_URL              = DEV?"https://help.yosvet.com/api-dev/login"           :   "https://admin.yosvet.com/api/login";         

export const NODE_URL               = "https://node.yosvet.com";                    
export const APP_URL                = 'https://app.yosvet.com/';                    
export const WWW_URL                = "https://yosvet.com/";

export const USER_KEY               = "usuario";
export const DEVICE_KEY             = "device";
export const BUSQUEDAS_GUARDADAS    = 'busquedas-guardadas';
export const AMAZONS3_URL           = "https://s3-eu-west-1.amazonaws.com/linkedvet/";
export const AMAZONS3_URL_RESIZE    = "https://mq1fda26bd.execute-api.eu-west-1.amazonaws.com/image/";

// CARPETAS 
export const CV_ANEXOS_IMG          = "cv-anexos-img/";
export const CV_ANEXOS_PDF          = "cv-anexos-pdf/";
export const EMPRESA_ANEXOS         = "empresa-anexos/";
export const PERFIL_ANEXOS          = "perfil-anexos/";
export const FOTOS_CONCURSOS        = "concursos/";
export const TITULOS_ANEXOS         = "titulos_carnes/";

// MÁXIMOS
export const OFERTAS_PUBLICADAS             = 10;
export const FOTOS_PARTICIPACION_CONCURSO   = 1;
export const VIDEOS_PARTICIPACION_CONCURSO  = 1;

// TAMAÑOS FOTOS
export const SMALL_THUMB_SIZE       = "150x0/";
export const MEDIUM_THUMB_SIZE      = "300x0/";
export const BIG_THUMB_SIZE         = "800x0/";
export const EVENT_SIZE             = "1200x0/";