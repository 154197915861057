import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { API } from 'src/providers/api';

import { APP_URL }             from '../../inc.global';
import { MessagePresenter } from 'src/providers/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popover-compartir-evento',
  templateUrl: './popover-compartir-evento.component.html',
  styleUrls: ['./popover-compartir-evento.component.scss'],
})

export class PopoverCompartirEventoComponent implements OnInit {

	@Input() EventoId: any;
    @Input() EventoTipo : string;
    AppUrl : string = APP_URL;
    mostrarCampoEmail : boolean = false;
    emailCompartir : string = null;
    ErrorEmailCompartir : string = null;
    
	constructor(
				private api:API,
				private popoverController:PopoverController,
                private messagePresenter: MessagePresenter,
                private translate:TranslateService
				) { }

	ngOnInit() {}

	
	async cancelar() {
		await this.popoverController.dismiss(null,'cancelar');
	}

    async copiarEnlace() {
        
        if (navigator.clipboard) {
            try {
                let url = this.AppUrl + "tabs/courses/detail/" + this.EventoId;
                
                if (this.EventoTipo=='WEBINAR') {
                    url = this.AppUrl + "tabs/masterclass/detail/" + this.EventoId;
                }
                
                await navigator.clipboard.writeText(url);
                this.messagePresenter.presentToast(this.translate.instant('ENLACE_COPIADO'), 3000);
                this.cancelar();
            } catch (err) {}
          }
    }

    
    async enviarPorMail() {
        
        if (!this.validateEmail(this.emailCompartir)) {
            this.ErrorEmailCompartir = this.translate.instant('EMAIL_NO_VALIDO');
            return;
        }

        try {
            this.cancelar();
            let datosEvento = null;
            if (this.EventoTipo=='WEBINAR') {
                datosEvento = await this.api.getDatosWebinar(this.EventoId);
            } else {
                datosEvento = await this.api.getDatosEvento(this.EventoId);
            }
            
            this.api.enviarEventoMail(datosEvento, this.emailCompartir);
            this.ErrorEmailCompartir = null;
            this.messagePresenter.presentToast(this.translate.instant('EVENTO_COMPARTIDO'), 3000);
            
            
        } catch (err) {
            this.ErrorEmailCompartir = "Error";
        }
        

        return;
    }


    validateEmail(email) {
        return String(email)
          .toLowerCase()
          .match(/^\S+@\S+\.\S+$/);
    };
}