import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { API } from 'src/providers/api';
import { BIG_THUMB_SIZE, AMAZONS3_URL_RESIZE }             from '../../inc.global';

@Component({
  selector: 'app-bloque-concurso',
  templateUrl: './bloque-concurso.component.html',
  styleUrls: ['./bloque-concurso.component.scss'],
})

export class BloqueConcursoComponent implements OnInit {

	ConcursosActivos : any = [];
	Dimensiones = BIG_THUMB_SIZE;
    AmazonS3Url = AMAZONS3_URL_RESIZE + this.Dimensiones;

	constructor(private api:API,
				private router:Router) { }

	ngOnInit() {
		this.getConcursosActivos()   
	}

	async getConcursosActivos() {
		const datos = await this.api.getConcursosActivos(2);
		if(datos) {
			this.ConcursosActivos = datos;
		}
	}

	abreConcursos() {
		this.router.navigateByUrl('tabs/contests');
	}

}
