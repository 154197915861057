import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { API } from 'src/providers/api';
import { LocalStorageService } from 'src/providers/localStorage.service';
import { UpdateService } from 'src/services/update.service';

import { USER_KEY }                 from '../../inc.global';
import { AMAZONS3_URL }             from '../../inc.global';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bloque-recomendados',
  templateUrl: './bloque-recomendados.component.html',
  styleUrls: ['./bloque-recomendados.component.scss'],
})

export class BloqueRecomendadosComponent implements OnInit {

	Posts : any = [];
    DatosUsuario : any = {};
	Locale = "";
	@Input() hook = 'side'; // side, vets, buscar
	placeHolderRepeticiones : any = [1,2,3,4,5,6,7,8];
    
	constructor(private localStorage:LocalStorageService,
				private api:API,
				private router:Router,
                private updateService:UpdateService,
                private translate:TranslateService,
				private alertCtrl:AlertController
				) 
				{ 
					
                    
                    
                    this.updateService.getLoginObservable().subscribe((data)=>{
                        if (data) {
                            
                            this.localStorage.get(USER_KEY).then(
                                (datos) => {	
                                    if (datos) {
                                        
                                        this.DatosUsuario = datos;
                                        this.Locale = datos.locale.toLowerCase();
                                        this.getTopPostsIdioma();
                                    }
                                    
                                }
                            );
                        }
                        
                    });		
				}

	ngOnInit() {
            
	}

	async getTopPostsIdioma() {
		let limit = 10;
		if (this.hook=='side') limit = 1;
		this.api.getTopPostsOut(1, this.Locale, limit).then(
			(response) => {
                if(Object.keys(response).length>0) {
                    this.Posts = response;
                }
			}
		).catch(error => { 
			
		});
	}

    editarPost(post) {
        const navigationExtras: NavigationExtras = {
            state: {
                PostId: post.PostId,
                prevPage: this.router.url 
            }
        };
		this.router.navigate(['post-edit/'], navigationExtras);
	}

    borrarPost(post) {
		
		this.api.borraPost(post.PostId).then(
			() => {
				let index = this["Posts"].indexOf(post);
				if(index > -1){
					this["Posts"].splice(index, 1);
					this.presentAlertPublicacionEliminada();	
				}
				
			}
		);
	}

    async presentAlertPublicacionEliminada() {
		const Mensaje = this.translate.instant('GENERAL_PUBLICACION_ELIMINADA');
		
		const alert = await this.alertCtrl.create({
		  cssClass: 'my-custom-class',
		  message: Mensaje,
		  buttons: ['OK']
		});
	
		await alert.present();	
	}
    
    ChangeStatusRecomendadoClick(datos) {
		
		this.api.recomiendaPost(datos.PostId, datos.Status).then(
			() => {
				//this.presentAlertPublicacionRecomendadaORecomendacionEliminada(datos.Status);
				// quito el aviso de momento
			}
		).catch(error => { });
	}

	abreBuscar() {
        const navigationExtras: NavigationExtras = {
            state: {
                prevPage: this.router.url 
            }
        };
        this.router.navigate(['explore'], navigationExtras);
    }
}
