import { Directive, HostListener, Input }   from '@angular/core';
import { Router, NavigationExtras }           from '@angular/router';
import { API }              from '../providers/api';
import { UpdateService }    from '../services/update.service';


@Directive({
    selector: '[dynamicContent]'
})

export class DynamicContentDirective {

    constructor(public router:Router,
                private updater:UpdateService,
                private api:API) {
        
    }

    @Input('dynamicContent') dynamicContent: string;

    @HostListener('click', ['$event']) onClick(e) {        
        let urlObjeto   = e.target.title;
        let tipoObjeto  = e.target.getAttribute('alt');

        
        
        if (tipoObjeto!=null) {
            e.stopPropagation();
        }

        if (tipoObjeto=='hashtag') this.openHash(urlObjeto);        
        if (tipoObjeto=='enlace') this.openLink(urlObjeto);        
    }

    public openLink(urlObjeto:string) {
        
        this.api.openLink(urlObjeto);
    }

    public openHash(urlObjeto:string) {
        
        const tag = urlObjeto.substring(1);
        const navigationExtras: NavigationExtras = {
            state: {
                tag: tag,
                prevPage: this.router.url
            }
        };
		this.router.navigate(['/tabs/explore'], navigationExtras);
        this.updater.paginaCambiada('explore');
        
    }
}