import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';

declare var navigator: any; 
declare var Connection: any;

@Injectable()
export class Connectivity {
 
    onDevice: boolean = false;
 
    constructor(public platform: Platform, 
                public toastCtrl : ToastController){
        this.platform = platform;
        this.platform.ready().then(() => {
            this.onDevice = this.platform.is('cordova');
            //this.presentToastPlatforms();
        });
        
    }

    isOnline(): boolean {
        let result : boolean = false;
        if(this.onDevice && navigator.connection) {
            result = navigator.connection.type !== "none";
        } else {
            result = navigator.onLine;
        }
        return result;
    }
    
    presentToastPlatforms(): void {
        let toast = this.toastCtrl.create({
            message: this.platform.platforms().toString(),
            duration: 10000,
            position: 'bottom'
        });

        //toast.present();
    }
    
    presentToastOffline(): void {
        let toast = this.toastCtrl.create({
            message: 'No hay conexión a internet',
            duration: 3000,
            position: 'bottom'
        });

        //toast.present();
    }

    presentToastCamposRequeridos():void {
        let toast = this.toastCtrl.create({
            message: 'Debe rellenar todos los campos requeridos',
            duration: 3000,
            position: 'bottom'
        });

        //toast.present();
    }

    checkWifiConnection() {
        return this.platform.ready().then(() => {
            var networkState = navigator.connection.type;
            var states = {};
            states[Connection.UNKNOWN]  = 'Unknown connection';
            states[Connection.ETHERNET] = 'Ethernet connection';
            states[Connection.WIFI]     = 'WiFi connection';
            states[Connection.CELL_2G]  = 'Cell 2G connection';
            states[Connection.CELL_3G]  = 'Cell 3G connection';
            states[Connection.CELL_4G]  = 'Cell 4G connection';
            states[Connection.CELL]     = 'Cell generic connection';
            states[Connection.NONE]     = 'No network connection';
            return(states[networkState]);
        });

    }

    
}