import { Injectable } 		from '@angular/core';
import { LoadingController, ToastController } 	from '@ionic/angular';

@Injectable()

export class MessagePresenter {

	miToast : any;
    loading: any;

  	constructor(private toastCtrl: ToastController, private loadingCtrl:LoadingController){}

	public presentToast(mensaje: string, duracion:number, posicion:any="top", coloracion:any="primary") {
		this.miToast = this.toastCtrl.create({
			message: mensaje,
			color: coloracion,
			position: posicion,
			duration: duracion
		}).then((toastData) => {
			toastData.present();
		});
	}
		
	async presentLoadingCustom(tipo:string='circles', mensaje:string='', duracion:number) {
		this.loading = await this.loadingCtrl.create({
			spinner: 'circles',
			duration: duracion,
			message: mensaje,
			translucent: true,
			cssClass: 'custom-class custom-loading',
			backdropDismiss: true
		});
		await this.loading.present();

	}

    dismiss() {
        this.loading.dismiss();
    }

    
}
