import { Component, OnInit }        from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ModalController }          from '@ionic/angular';
import { RespuestasPage }           from '../../app/empleo-pages/respuestas/respuestas.page';
import { LocalStorageService }      from '../../providers/localStorage.service';
import { USER_KEY }                 from '../../inc.global';
import { CvPage }                   from '../../app/cv-pages/cv/cv.page';
import { API } from 'src/providers/api';


@Component({
  selector: 'app-popover-inscrito',
  templateUrl: './popover-inscrito.component.html',
  styleUrls: ['./popover-inscrito.component.scss'],
})

export class PopoverInscritoComponent implements OnInit {

	anuncio: any = {};
    idUsuario : number =null;
    idOferta : number = null;
    nombreUsuario = "";
    
    DatosUsuario : any = [];

    constructor(private storage:LocalStorageService,
                private router:Router,
                private api:API,
                private modalCtrl:ModalController
                ) {
                    this.storage.get(USER_KEY).then(
                        (datosUsuario) => {
                            this.DatosUsuario = datosUsuario;
                        }
                    );
                }

    ngOnInit() {}

    /*
    async verCv() {
        const modal = await this.modalCtrl.create({
            component: CvPage,
            cssClass: 'modalCV',
            showBackdrop: true,
            swipeToClose: true,
            componentProps: {
                idUsuario: this.idUsuario
            }
        });
        
        return await modal.present();
    }
    */

    async verCv() {
        
        const navigationExtras: NavigationExtras = {
            state: {
                idUsuario: this.idUsuario,
                prevPage: this.router.url 
            }
        };

        this.router.navigate(['tabs/employ/cv'], navigationExtras);                    
       
		return;
    }

    verMuro() {
        const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: this.idUsuario,
                prevPage: this.router.url 
            }
        };

        if (this.DatosUsuario.id != this.idUsuario) {  
            this.router.navigate(['wall/'], navigationExtras);                    
        } else {
            this.router.navigate(['my-wall/'], navigationExtras);                    
        }
       
		return;
    }

    async verRespuestas() {
        const modal = await this.modalCtrl.create({
            component: RespuestasPage,
            cssClass: 'modalCV',
            showBackdrop: true,
            swipeToClose: true,
            componentProps: {
                idOferta: this.idOferta,
                idUsuario: this.idUsuario,
                nombreUsuario: this.nombreUsuario
            }
        });

        return await modal.present();
    }

    chatDetalle(usuarioId, usuarioNombre) {
			
		let destinatarioFoto = "";
		let destinatarioId = null;
		let destinatarioNombre = "";
		
		if (usuarioId!=null) {
			destinatarioId = usuarioId;
			destinatarioNombre = usuarioNombre;
		}
		
		this.api.getChatId(destinatarioId).then(
			(response) => {
                if (!response) return;
                const navigationExtras: NavigationExtras = {
                    state: {
                        chatId: response,
                        destinatarioId: destinatarioId,
                        destinatarioNombre: destinatarioNombre,
                        prevPage: this.router.url
                    }
                };
                this.router.navigate(['chat-detail/'], navigationExtras);
			}
		);
		
		
	}
}
