import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { LocalStorageService } from 'src/providers/localStorage.service';
import { UpdateService } from 'src/services/update.service';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';

import { API } from 'src/providers/api';
import { Device }                   from '@capacitor/device';

import { AMAZONS3_URL_RESIZE, BIG_THUMB_SIZE, AMAZONS3_URL, USER_KEY }             from '../../inc.global';
import { PopoverCompartirPostComponent }    from '../popover-compartir-post/popover-compartir-post.component';
import { PopoverPostComponent }             from '../popover-post/popover-post.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-post',
	templateUrl: './post.component.html',
	styleUrls: ['./post.component.scss'],
})

export class PostComponent implements OnInit, OnDestroy {
    Dimensiones = BIG_THUMB_SIZE;
    AmazonS3Url = AMAZONS3_URL_RESIZE + this.Dimensiones;
    AmazonS3UrlNoImage = AMAZONS3_URL;


	DatosUsuario : any = {};
	Locale = "";
	VerEntero : boolean = false;
	TieneAnexos : boolean = false;
	
    EnlacesTexto    = [];

    pwa : boolean = false;

	@Input() Post: any;
    @Input() ModoDebug : boolean = false;
	@Input() Seccion : string = null;
	@Input() DatosDestinatario : any = [];
    @Input() Hook : string = null;
    
	@Output() ChangeStatusRecomendadoClick: EventEmitter<any> = new EventEmitter();
	@Output() ChangeStatusLikeClick: EventEmitter<any> = new EventEmitter();
    @Output() ChangeStatusGuardadoClick: EventEmitter<any> = new EventEmitter();
	
    AnexosVideoParseados = [];
    Recargando : boolean = false;

    private subscriptions = new Subscription();

	constructor(public storage:LocalStorageService,
				private router:Router,
                private popoverCtrl:PopoverController,
				public sanitizer:DomSanitizer,
				private updateService:UpdateService,
                private api:API,
                private host: ElementRef<HTMLElement>
                ) { 

                    
			this.storage.get(USER_KEY).then(
				(datos) => {	
					this.DatosUsuario = datos;
					this.Locale = datos.locale.toLowerCase();
					
				}
			);

			const like = this.updateService.getLikePostObservable().subscribe((data)=>{
				this.Post.likes_totales = data.numLikes;
				this.Post.like_mio = data.status;
			});

			const recomendado = this.updateService.getRecomendadoPostObservable().subscribe((data)=>{
				this.Post.recomendados_totales = data.numRecomendados;
				this.Post.recomendado_mio = data.status;
			});

			const sub = this.updateService.getComentarioObservable().subscribe((data)=>{
				if (data) {
					if (this.Post.PostId==data.ComentarioPostId) {
						if (this.Post.Comentarios) {
							this.Post.Comentarios.unshift(data);
						} else {
							this.Post.Comentarios = [];
							this.Post.Comentarios.unshift(data);
						}

						this.Post.comentarios_count = this.Post.comentarios_count + 1;
					}
					
				}
			});

			this.subscriptions.add(sub);
			this.subscriptions.add(like);
			this.subscriptions.add(recomendado);		
            
	}

  	ngOnInit() {
        //console.log(this.Seccion);
        this.Post?.Comentarios?.forEach(comentario => {
            if (comentario.AnexosVideo && comentario.AnexosVideo.length>0){
                comentario.ComentariosAnexosVideoParseados = [];
                comentario.AnexosVideo.forEach((element, index) => {
                    
                    const anexoArray = [element.playable, this.dameFrameVideo(element.url), element.id, element.url];
                    comentario.ComentariosAnexosVideoParseados.push(anexoArray);
                    
                    if (!element.playable) {
                        setTimeout(()=>{
                            this.api.vimeoCheckStatus(element.url).then((response) => {
                                if (response.transcode.status=='complete') {
                                    comentario.ComentariosAnexosVideoParseados[index][0] = true;
                                    this.api.vimeoComentarioSetPlayableStatus(element.id);
                                }
                            });
                        }, 3000);
                    };
                }); 
            }
        });
        
        if (this.Post?.AnexosVideo && this.Post?.AnexosVideo.length>0) {
            this.Post?.AnexosVideo.forEach((element, index) => {
                const anexoArray = [element.playable, this.dameFrameVideo(element.url), element.id, element.url];
                this.AnexosVideoParseados.push(anexoArray);
                
                if (!element.playable) {
                    setTimeout(()=>{
                        this.api.vimeoCheckStatus(element.url).then((response) => {
                            if (response.transcode.status=='complete') {
                                this.AnexosVideoParseados[index][0] = true;
                                this.api.vimeoPostSetPlayableStatus(element.id);
                            }
                        });
                    }, 3000);
                };
            }); 
        }

        if (this.Post?.PostDescripcion && this.Post?.PostDescripcion!='') {
            const exp =/(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            let tempEnlaces = this.Post?.PostDescripcion.match(exp);
            if (tempEnlaces?.length>0) {
                tempEnlaces.forEach(element => {
                    let tipo = "globe-outline";
                    const contieneVideo = element.includes('youtube') || element.includes('vimeo') || element.includes('youtu.be');
                    if (contieneVideo) tipo = "play-outline";
                    const enlace = { enlace: element, tipo: tipo};
                    this.EnlacesTexto.push(enlace);
                });
            }
        }

        this.storage.get("device").then((datos)=>{
            if (datos) {
                if (datos.platform=='web') this.pwa = true;
            } else {
                this.saveDeviceInfo();
            }
        });
	}

    async recargaVideo(element, e=null) {
        if (e) e.stopPropagation();
        this.Recargando = true;
        
        this.api.vimeoCheckStatus(element[3]).then((response) => {
            if (response.transcode.status=='complete') {
                element[0] = true;
                this.api.vimeoPostSetPlayableStatus(element[2]);
                this.Recargando = false;
            }
        });
        
        setTimeout(()=>{ this.Recargando = false;}, 5000);
    }

    async saveDeviceInfo() {
        const deviceInfo = await Device.getInfo();
        if (deviceInfo.platform=='web') this.pwa = true;
        this.storage.set("device", deviceInfo);
    }

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	changeEnteroFlag() {
		this.VerEntero = !this.VerEntero;
        
	}
	
	changeStatusRecomendado(status) {
		this.Post.recomendado_mio = !status;
		
		if (status===1 && this.Post.recomendados_totales>0) {
			this.Post.recomendados_totales = parseInt(this.Post.recomendados_totales) - 1;
		} else {
			this.Post.recomendados_totales = parseInt(this.Post.recomendados_totales) + 1;
		}
		this.ChangeStatusRecomendadoClick.emit({PostId: this.Post.PostId, Status:status});
		

	}

	changeStatusLike(status) {
		this.Post.like_mio = !status;
		
		if (status===1 && this.Post.likes_totales>0) {
			this.Post.likes_totales = parseInt(this.Post.likes_totales) - 1;
		} else {
			this.Post.likes_totales = parseInt(this.Post.likes_totales) + 1;
		}
		this.ChangeStatusLikeClick.emit({PostId: this.Post.PostId, Status:status});
	}

    changeStatusGuardado(status) {
		this.Post.guardado_mio = !status;
		
		if (status===1 && this.Post.guardados_totales>0) {
			this.Post.guardados_totales = parseInt(this.Post.guardados_totales) - 1;
		} else {
			this.Post.guardados_totales = parseInt(this.Post.guardados_totales) + 1;
		}
		this.ChangeStatusGuardadoClick.emit({PostId: this.Post.PostId, Status:status});
	}

	

	abreMuro(Post) {
        const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: Post.UsuarioId,
                prevPage: this.router.url 
            }
        };

        this.storage.get(USER_KEY).then(
            (response) => {
                this.Locale = response.locale.toLowerCase();
                
                if (response.id != Post.UsuarioId) {  
                    this.router.navigate(['wall/'], navigationExtras);                    
                } else {
                    this.router.navigate(['my-wall/'], navigationExtras);                    
                }
            }
        );	
	}
    
    abreMuroUsuario(usuarioId) {
        
        const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: usuarioId,
                prevPage: this.router.url 
            }
        };

        this.storage.get(USER_KEY).then(
            (response) => {
                this.Locale = response.locale.toLowerCase();
                
                if (response.id != usuarioId) {  
                    this.router.navigate(['wall/'], navigationExtras);                    
                } else {
                    this.router.navigate(['my-wall/'], navigationExtras);                    
                }
            }
        );	
	}

	abreVisor(ev, AnexosUrls, IndiceFoto) {
        ev.stopPropagation();
		const navigationExtras: NavigationExtras = {
            state: {
                slides: AnexosUrls, 
                nombre: this.Post.UsuarioNombre, 
                texto: this.Post.PostDescripcion, 
                indiceFoto: IndiceFoto,
                prevPage: this.router.url
            }
        };
		this.router.navigate(['viewer'], navigationExtras);

		return;
	}

    dameImagenVideo(enlace) {
        
        if (enlace?.tipo!='play-outline') return "";

        let UrlImagen       = "";
        let CodigoVideo     = "";

        if (enlace?.tipo=='play-outline') {
            

            if (enlace?.enlace.includes('youtu.be')===true) { 
                CodigoVideo = enlace?.enlace.substring(enlace?.enlace.lastIndexOf('/') + 1);
                UrlImagen = "https://img.youtube.com/vi/" + CodigoVideo + "/hqdefault.jpg";
            }

            if (enlace?.enlace.includes('youtube')===true) { 
                CodigoVideo = enlace?.enlace.substring(enlace?.enlace.lastIndexOf('=') + 1);
                UrlImagen = "https://img.youtube.com/vi/" + CodigoVideo + "/hqdefault.jpg";
            }
            
            if (enlace?.enlace.includes('vimeo')===true) {
                UrlImagen = "../assets/imgs/vimeo_placeholder.png";
            }
        }
        
        
        return UrlImagen;
    }

	abreComentarios(Post) {
        const navigationExtras: NavigationExtras = {
            state: {
                id: Post.PostId,
                prevPage: this.router.url
            }
        };
		this.router.navigate(['tabs/feed/comments/'], navigationExtras);
		return;
	}

	abreComentarioNuevo(Post) {
        const navigationExtras: NavigationExtras = {
            state: {
                postId: this.Post.PostId,
                prevPage: this.router.url
            }
        };
		this.router.navigate(['comentario-new/'], navigationExtras);
		return;
	}
    
    openPdf(ev, pdf) {
        ev.stopPropagation();
        if (this.pwa) {
            window.open(this.AmazonS3UrlNoImage + pdf.url, '_blank');
        } else {
            this.api.openPdf(this.AmazonS3UrlNoImage + pdf.url);
        }        
    }
 
    openLink(url) {
        this.api.openLink(url);
    }

    async muestraPopover(ev:Event) {
        ev.stopPropagation();
        
        // estilo para el popover solo si pwa
        const deviceInfo = await this.storage.get("device");
        let css = ""; 
        let modePopup : any = "md";
        if (deviceInfo.platform=='web') {
            css = "custom-popover";
            modePopup = "ios";
        }
        
        const popover = await this.popoverCtrl.create({
            component: PopoverPostComponent,
            event: ev,
            showBackdrop: false,
            dismissOnSelect: false,
            mode: modePopup,
            arrow:false,
            side:'bottom',
            cssClass: css,
            componentProps: {
                Post: this.Post
            }
        });
        
        await popover.present();
        
        const { role } = await popover.onDidDismiss();
        if (role == 'borrado') {
            this.host.nativeElement.remove(); // autodestrucción
            
        }
        
    }

    async muestraPopoverCompartir(ev:Event) {
        ev.stopPropagation();
        
        // estilo para el popover solo si pwa
        const deviceInfo = await this.storage.get("device");
        let css = ""; 
        let modePopup : any = "md";
        if (deviceInfo.platform=='web') {
            css = "custom-popover";
            modePopup = "ios";
        }
        
        const popover = await this.popoverCtrl.create({
            component: PopoverCompartirPostComponent,
            event: ev,
            showBackdrop: false,
            dismissOnSelect: false,
            mode: modePopup,
            arrow:false,
            side:'top',
            cssClass: css,
            componentProps: {
                Post: this.Post
            }
        });
        
        await popover.present();
        
        const { role } = await popover.onDidDismiss();
        if (role == 'borrado') {
            this.host.nativeElement.remove(); // autodestrucción
            
        }
        
    }

    async borraComentario(ev, Comentario) {
        ev.stopPropagation();
        const borrado = await this.api.borraPostComentario(this.Post.PostId, Comentario.ComentarioId);
        
        if (borrado) {
            let index = this.Post.Comentarios.indexOf(Comentario);
				if(index > -1){
					this.Post.Comentarios.splice(index, 1);
				}	
        }
        return borrado;
    }

    dameFrameVideo(videoId) {
        
        const url = "https://player.vimeo.com/video/" + videoId + "?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479";
        const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        
        return safeUrl;
    }

}