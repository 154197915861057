import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient } 	from '@angular/common/http';
import { SwiperModule } 			        from 'swiper/angular';

/* i18n */
import {TranslateModule, TranslateLoader}   from '@ngx-translate/core';
import {TranslateHttpLoader}                from '@ngx-translate/http-loader';

// Providers
import { Connectivity }         from '../providers/connectivity_provider';
import { API }                  from '../providers/api';
import { LocalStorageService}   from '../providers/localStorage.service';
import { MessagePresenter }		from '../providers/message.service';
import { AwsService }			from '../providers/aws.service';
//import { PdfHelperService }     from '../providers/pdfHelper.service';

// Plugins
import { IonicStorageModule }   from '@ionic/storage-angular';
import { ToastController } 		from '@ionic/angular';


import { ServiceWorkerModule }  from '@angular/service-worker';
import { environment }          from '../environments/environment';

import { ComponentsModule } 	from '../components/components.module';

import { TRADUCCIONES_URL } from '../inc.global';

export function createTranslateLoader(http: HttpClient) {
    const url = TRADUCCIONES_URL;
    
	return new TranslateHttpLoader(http, url, '');
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [	HttpClientModule, 
				TranslateModule.forRoot({
					loader: {
						provide: TranslateLoader,
						useFactory: (createTranslateLoader),
						deps: [HttpClient]
					}
				}),
				SwiperModule,
                IonicStorageModule.forRoot(),
				BrowserModule,
				IonicModule.forRoot({backButtonText: ''}), 
				AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', {
                    enabled: environment.production,
                    // Register the ServiceWorker as soon as the application is stable
                    // or after 30 seconds (whichever comes first).
                    registrationStrategy: 'registerWhenStable:30000'
                }),
                ComponentsModule
            ],
	providers: [Connectivity, API, LocalStorageService, ToastController, MessagePresenter,AwsService, 
				{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
	bootstrap: [AppComponent],
})

export class AppModule {}
