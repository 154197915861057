import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { API } from 'src/providers/api';
import { LocalStorageService } from 'src/providers/localStorage.service';

import { USER_KEY, AMAZONS3_URL_RESIZE, MEDIUM_THUMB_SIZE }             from '../../../inc.global';

@Component({
  selector: 'app-anuncio-interesados',
  templateUrl: './anuncio-interesados.page.html',
  styleUrls: ['./anuncio-interesados.page.scss'],
})

export class AnuncioInteresadosPage implements OnInit {

	DatosUsuario : any = {};
	Page = 1;
    Fin : boolean = false;
    Flag : boolean = false;

    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    Dimensiones = MEDIUM_THUMB_SIZE;
    AmazonS3Url = AMAZONS3_URL_RESIZE + this.Dimensiones;

    @Input() idAnuncio : number;
    @Input() tituloAnuncio : string;

    Interesados = [];

	constructor(private api:API,
				private router: Router,
                private modalCtrl: ModalController,
                private storage:LocalStorageService) { 
                    
                
                this.storage.get(USER_KEY).then(
                    (datosUsuario) => {
                        this.DatosUsuario = datosUsuario;
                    }
                );
	}

	ngOnInit() {
        this.getInteresadosAnuncio();
	}

    loadInteresados(event) {
		this.Page ++;
		this.getInteresadosAnuncio();
	}

    getInteresadosAnuncio() { 
        this.api.getInteresados(this.idAnuncio).then(
            response => {
                if (response) {
                    this.Interesados = response;
                    //console.log(this.Interesados);
                } else {
                    return;
                }
            }
        );
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
    
    abreChatConInteresado(ev, interesadoId) {
        ev.stopPropagation();
        this.modalCtrl.dismiss();
        this.api.getChatId(interesadoId).then(
            (response) => {
                if (!response) return;
                const navigationExtras: NavigationExtras = {
                    state: {
                        chatId: response,
                        destinatarioId: interesadoId,
                        prevPage: this.router.url
                    }
                };
                this.router.navigate(['chat-detail/'], navigationExtras);
            }
        );
    }            
            
    abreMuro(UsuarioId) {
        this.modalCtrl.dismiss();

        const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: UsuarioId,
                prevPage: this.router.url 
            }
        };

        if (this.DatosUsuario.id != UsuarioId) {  
            this.router.navigate(['wall/'], navigationExtras);                    
        } else {
            this.router.navigate(['my-wall/'], navigationExtras);                    
        }
       
		return;
    }

}
