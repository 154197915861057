<div *ngIf="Posts?.length>0">
    <ion-list lines="none" class="ion-no-margin">
        <ion-item>
            <ion-label>
                <h3>{{'GENERAL_RECOMENDADO'|translate}}</h3>
            </ion-label>
        </ion-item>
        <app-post *ngFor="let Post of Posts" [Hook]="hook" [Post]="Post" (EditaPostClick)="editarPost($event)" (BorraPostClick)="borrarPost($event)" (ChangeStatusRecomendadoClick)="ChangeStatusRecomendadoClick($event)"></app-post>
        <ion-item *ngIf="hook=='side'">
            <ion-label>
                <div class="ion-float-right"><ion-button (click)="abreBuscar()" size="small" fill="clear"><ion-icon name="chevron-forward-outline" slot="end"></ion-icon><ion-label>{{'BTN_VER_MAS'|translate}}</ion-label></ion-button></div>
            </ion-label>
        </ion-item>
    </ion-list>
</div>