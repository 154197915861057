<ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" pullFactor="0.5" pullMin="100" pullMax="200">
    <ion-refresher-content 
        pullingIcon="chevron-down-circle-outline"
        refreshingSpinner="crescent">
    </ion-refresher-content>
</ion-refresher>

<div >
    <ion-list class="ion-no-margin">
        
        <h3 *ngIf="SugeridosLocalidad && SugeridosLocalidad.length>0" color="primary">{{'VETS_DE_TU_LOCALIDAD'|translate}}</h3>
        <ion-item button detail="false" *ngFor="let sugerido of SugeridosLocalidad" (click)="abreMuro(sugerido)" class="AlturaMax">
            <ion-avatar slot="start">
                <img *ngIf="sugerido.foto" [src]="AmazonS3Url + sugerido.foto"/>
                <img *ngIf="!sugerido.foto" src="../assets/imgs/user_default.png"/>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
                <ion-badge *ngIf="sugerido.TeSigue" size="small" color="light" mode="md">{{'TITLE_TE_SIGUE'|translate}}</ion-badge>
                <ion-button *ngIf="!sugerido.LeSigues" (click)="follow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person-add-outline"></ion-icon></ion-button>
                <ion-button *ngIf="sugerido.LeSigues" (click)="unfollow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person"></ion-icon></ion-button>
                <h2>{{sugerido.nombre}}</h2>
                
                <div class="ion-float-left"><small>{{sugerido.ProvinciaNombre}}</small>&nbsp;&bullet;&nbsp;</div>
                <div class="ion-float-left" *ngIf="sugerido.ActividadNombre"><small>{{sugerido.ActividadNombre}}</small></div>
                <hr style="clear: both;">

                <!--
                <div class="ion-text-left" *ngIf="sugerido.Especies?.length>0 && hook!='side'">
                    <img src="{{AmazonS3Url + especie.logo}}" class="logoEspecie" *ngFor="let especie of sugerido.Especies" />
                </div>
                -->
                
                <p style="clear: both" *ngIf="sugerido.descripcion && hook=='side'">{{sugerido.descripcion | truncate:70}}</p>
                <p style="clear: both" *ngIf="sugerido.descripcion && hook!='side'">{{sugerido.descripcion | truncate:240}}</p>

                <!--
                <div style="clear: both" *ngIf="sugerido.Cargos.length>0 && hook!='side'">
                    <ion-button fill="outline" color="primary" size="small" mode="ios" *ngFor="let Cargo of sugerido.Cargos">{{Cargo['nombre_' + Locale]}}</ion-button>
                </div>
                -->
            </ion-label>
        </ion-item>
        
        <h3 *ngIf="SugeridosIntereses && SugeridosIntereses.length>0" color="primary">{{'VETS_CON_TUS_INTERESES'|translate}}</h3>
        <ion-item button detail="false" *ngFor="let sugerido of SugeridosIntereses" (click)="abreMuro(sugerido)" class="AlturaMax">
            <ion-avatar slot="start">
                <img *ngIf="sugerido.foto" [src]="AmazonS3Url + sugerido.foto"/>
                <img *ngIf="!sugerido.foto" src="../assets/imgs/user_default.png"/>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
                <ion-badge *ngIf="sugerido.TeSigue" size="small" color="light" mode="md">{{'TITLE_TE_SIGUE'|translate}}</ion-badge>
                <ion-button *ngIf="!sugerido.LeSigues" (click)="follow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person-add-outline"></ion-icon></ion-button>
                <ion-button *ngIf="sugerido.LeSigues" (click)="unfollow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person"></ion-icon></ion-button>
                <h2>{{sugerido.nombre}}</h2>
                
                <div class="ion-float-left"><small>{{sugerido.ProvinciaNombre}}</small>&nbsp;&bullet;&nbsp;</div>
                <div class="ion-float-left" *ngIf="sugerido.ActividadNombre"><small>{{sugerido.ActividadNombre}}</small></div>
                <hr style="clear: both;">
            
                <!--
                <div class="ion-float-left" *ngIf="sugerido.Especies?.length>0 && hook!='side'">
                    <img src="{{AmazonS3Url + especie.logo}}" class="logoEspecie" *ngFor="let especie of sugerido.Especies" />
                </div>
                -->

                <p style="clear: both" *ngIf="sugerido.descripcion && hook=='side'">{{sugerido.descripcion | truncate:70}}</p>
                <p style="clear: both" *ngIf="sugerido.descripcion && hook!='side'">{{sugerido.descripcion | truncate:240}}</p>

                <!--
                <div style="clear: both" *ngIf="sugerido.Cargos.length>0 && hook!='side'">
                    <ion-button fill="outline" color="primary" size="small" mode="ios" *ngFor="let Cargo of sugerido.Cargos">{{Cargo['nombre_' + Locale]}}</ion-button>
                </div>
                -->
            </ion-label>
        </ion-item>
    
        <h3 *ngIf="SugeridosUniversidad && SugeridosUniversidad.length>0" color="primary">{{'VETS_DE_TU_UNIVERSIDAD'|translate}}</h3>
        <ion-item button detail="false" *ngFor="let sugerido of SugeridosUniversidad" (click)="abreMuro(sugerido)" class="AlturaMax">
            <ion-avatar slot="start">
                <img *ngIf="sugerido.foto" [src]="AmazonS3Url + sugerido.foto"/>
                <img *ngIf="!sugerido.foto" src="../assets/imgs/user_default.png"/>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
                <ion-badge *ngIf="sugerido.TeSigue" size="small" color="light" mode="md">{{'TITLE_TE_SIGUE'|translate}}</ion-badge>
                <ion-button *ngIf="!sugerido.LeSigues" (click)="follow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person-add-outline"></ion-icon></ion-button>
                <ion-button *ngIf="sugerido.LeSigues" (click)="unfollow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person"></ion-icon></ion-button>
                <h2>{{sugerido.nombre}}</h2>
                
                <div class="ion-float-left"><small>{{sugerido.ProvinciaNombre}}</small>&nbsp;&bullet;&nbsp;</div>
                <div class="ion-float-left" *ngIf="sugerido.ActividadNombre"><small>{{sugerido.ActividadNombre}}</small></div>
                <hr style="clear: both;">

                <!--
                <div class="ion-float-left" *ngIf="sugerido.Especies?.length>0 && hook!='side'">
                    <img src="{{AmazonS3Url + especie.logo}}" class="logoEspecie" *ngFor="let especie of sugerido.Especies" />
                </div>
                -->

                <p style="clear: both" *ngIf="sugerido.descripcion && hook=='side'">{{sugerido.descripcion | truncate:70}}</p>
                <p style="clear: both" *ngIf="sugerido.descripcion && hook!='side'">{{sugerido.descripcion | truncate:240}}</p>

                <!--
                <div style="clear: both" *ngIf="sugerido.Cargos.length>0 && hook!='side'">
                    <ion-button fill="outline" color="primary" size="small" mode="ios" *ngFor="let Cargo of sugerido.Cargos">{{Cargo['nombre_' + Locale]}}</ion-button>
                </div>
                -->
            </ion-label>
        </ion-item>

        <h3 *ngIf="SugeridosEspecies && SugeridosEspecies.length>0" color="primary">{{'VETS_CON_ESPECIES_EN_COMUN'|translate}}</h3>
        <ion-item button detail="false" *ngFor="let sugerido of SugeridosEspecies" (click)="abreMuro(sugerido)" class="AlturaMax">
            <ion-avatar slot="start">
                <img *ngIf="sugerido.foto" [src]="AmazonS3Url + sugerido.foto"/>
                <img *ngIf="!sugerido.foto" src="../assets/imgs/user_default.png"/>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
                <ion-badge *ngIf="sugerido.TeSigue" size="small" color="light" mode="md">{{'TITLE_TE_SIGUE'|translate}}</ion-badge>
                <ion-button *ngIf="!sugerido.LeSigues" (click)="follow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person-add-outline"></ion-icon></ion-button>
                <ion-button *ngIf="sugerido.LeSigues" (click)="unfollow($event, sugerido)" class="ion-float-right" fill="clear" size="small"><ion-icon slot="icon-only" name="person"></ion-icon></ion-button>
                <h2>{{sugerido.nombre}}</h2>
                
                <div class="ion-float-left"><small>{{sugerido.ProvinciaNombre}}</small>&nbsp;&bullet;&nbsp;</div>
                <div class="ion-float-left" *ngIf="sugerido.ActividadNombre"><small>{{sugerido.ActividadNombre}}</small></div>
                <hr style="clear: both;">

                <!--
                <div class="ion-float-left" *ngIf="sugerido.Especies?.length>0 && hook!='side'">
                    <img src="{{AmazonS3Url + especie.logo}}" class="logoEspecie" *ngFor="let especie of sugerido.Especies" />
                </div>
                -->

                <p style="clear: both" *ngIf="sugerido.descripcion && hook=='side'">{{sugerido.descripcion | truncate:70}}</p>
                <p style="clear: both" *ngIf="sugerido.descripcion && hook!='side'">{{sugerido.descripcion | truncate:240}}</p>

                <!--
                <div style="clear: both" *ngIf="sugerido.Cargos.length>0 && hook!='side'">
                    <ion-button fill="outline" color="primary" size="small" mode="ios" *ngFor="let Cargo of sugerido.Cargos">{{Cargo['nombre_' + Locale]}}</ion-button>
                </div>
                -->
            </ion-label>
        </ion-item>

        <ion-item *ngIf="hook=='side'">
            <ion-label>
                <div class="ion-float-right"><ion-button (click)="abreVets()" size="small" fill="clear"><ion-icon name="chevron-forward-outline" slot="end"></ion-icon><ion-label>{{'BTN_VER_MAS'|translate}}</ion-label></ion-button></div>
            </ion-label>
        </ion-item>
    </ion-list>
</div>