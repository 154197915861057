import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Pipe({
  name: 'fechaFormateada'
})

export class FechaFormateadaPipe implements PipeTransform {
    
    constructor(private translate: TranslateService) {
    }

	transform(fecha: string): string {
		
		// asignar el valor de las unidades en milisegundos
		let msecPerMinute = 1000 * 60;
		let msecPerHour 	= msecPerMinute * 60;
		let msecPerDay 		= msecPerHour * 24;
		let msecPerWeek		= msecPerDay * 7;
		let msecPerMonth 	= msecPerWeek * 4;
		let msecPerYear		= msecPerMonth * 12; 

		// asignar la fecha en milisegundos
		let date = new Date(fecha);
		let dateMsec = date.getTime();

		let now = new Date();
		let nowMsec = now.getTime();

		// Obtener la diferencia en milisegundos
		let interval = nowMsec - dateMsec;

		// Calcular cuentos días contiene el intervalo. Substraer cuantos días
		//tiene el intervalo para determinar el sobrante
		let years = Math.floor(interval / msecPerYear);
		interval = interval - (years * msecPerYear);
		if (years==1) return this.translate.instant('FECHAS_UN_ANYO');
		if (years>1) return `${years} ` + this.translate.instant('FECHAS_ANYOS');

		let months = Math.floor(interval / msecPerMonth);
		interval = interval - (months * msecPerMonth);
		if (months==1) return this.translate.instant('FECHAS_UN_MES');
		if (months>1) return `${months} ` + this.translate.instant('FECHAS_MESES');

		let weeks = Math.floor(interval / msecPerWeek);
		interval = interval - (weeks * msecPerWeek);
		if (weeks==1) return this.translate.instant('FECHAS_UNA_SEMANA');
		if (weeks>1) return `${weeks} ` + this.translate.instant('FECHAS_SEMANAS');

		let days = Math.floor(interval / msecPerDay );
		interval = interval - (days * msecPerDay );
		if (days==1) return this.translate.instant('FECHAS_UN_DIA');
		if (days>1) return `${days} ` + this.translate.instant('FECHAS_DIAS');

		// Calcular las horas , minutos y segundos
		var hours = Math.floor(interval / msecPerHour );
		interval = interval - (hours * msecPerHour );
		if (hours==1) return this.translate.instant('FECHAS_UNA_HORA');
		if (hours>1) return `${hours} ` + this.translate.instant('FECHAS_HORAS');

		var minutes = Math.floor(interval / msecPerMinute );
		interval = interval - (minutes * msecPerMinute );
		if (minutes<=1) return this.translate.instant('FECHAS_UN_MINUTO');
		if (minutes>1) return `${minutes} ` + this.translate.instant('FECHAS_MINUTOS');
		
	}

	
}
