<ion-header>
	<ion-toolbar>
		<ion-title class="ion-text-left">{{tituloAnuncio}}</ion-title>
        <ion-buttons slot="end"><ion-button (click)="dismiss()"><ion-icon slot="icon-only" name="close-outline"></ion-icon></ion-button></ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
    
    <ion-list>
        <ion-item button *ngFor="let interesado of Interesados" (click)="abreMuro(interesado.id)">
            <ion-avatar slot="start">
                <img *ngIf="interesado.foto" [src]="AmazonS3Url + interesado.foto" />
                <img *ngIf="!interesado.foto" src="../assets/imgs/user_default.png" class="AvatarFade"/>
            </ion-avatar>
            <ion-label class="ion-text-wrap">
                <strong>{{interesado.nombre}}</strong><br>
                {{interesado.email}}<br>
                <span *ngIf="interesado.telefono">Tel. {{interesado.telefono}}</span><br>
                <p *ngIf="interesado.comentarios">{{interesado.comentarios}}</p>
            </ion-label>
            <ion-button slot="end" fill="clear" size="large" (click)="abreChatConInteresado($event, interesado.id)"><ion-icon name="chatbubbles-outline" slot="icon-only"></ion-icon></ion-button>
        </ion-item>
        <ion-item *ngIf="Interesados.length==0">{{'ANUNCIOS_NO_INTERESADOS'|translate}}</ion-item>
    </ion-list>
	
</ion-content>
