import { Component, OnInit }                    from '@angular/core';
import { Router, NavigationExtras }             from '@angular/router';
import { API }                                  from '../../providers/api';
import { MessagePresenter }                     from '../../providers/message.service';
import { TranslateService }                     from '@ngx-translate/core';
import { UpdateService }                        from '../../services/update.service';
import { ModalController, PopoverController }   from '@ionic/angular';
import { AnuncioInteresadosPage }                from '../../app/anuncios-pages/anuncio-interesados/anuncio-interesados.page';


@Component({
  selector: 'app-popover-anuncio',
  templateUrl: './popover-anuncio.component.html',
  styleUrls: ['./popover-anuncio.component.scss'],
})
export class PopoverAnuncioComponent implements OnInit {

	anuncio: any = {};

    constructor(private router:Router,
                private api:API,
                private modalCtrl:ModalController,
                private popoverController:PopoverController,
                private updater:UpdateService,
                private translate: TranslateService,
                private messagePresenter:MessagePresenter) { }

    ngOnInit() {}

    editarAnuncio(anuncio) {
        const navigationExtras: NavigationExtras = {
            state: {
                id: anuncio.id,
                prevPage: this.router.url 
            }
        };
        this.router.navigate(['ad-edit/'], navigationExtras);
    }

    eliminarPublicado(anuncio) {
        this.api.eliminarPublicado(anuncio).then(
            () => {
                this.updater.nuevoAnuncio();
                this.messagePresenter.presentToast(this.translate.instant('ANUNCIOS_ANUNCIO_PUBLICADO_ELIMINADO'),2000);
            }
        );
    }

	renovarAnuncio(anuncio) {
		this.api.renovarPublicado(anuncio).then(
			() => {
				this.updater.nuevoAnuncio();
				this.messagePresenter.presentToast(this.translate.instant('ANUNCIOS_RENOVADO'), 2000);
			}
		)
	}

    async cancelar() {
		await this.popoverController.dismiss();
	}

    async listarInteresados(anuncio) {
        const modal = await this.modalCtrl.create({
            component: AnuncioInteresadosPage,
            cssClass: 'modalCV',
            showBackdrop: true,
            swipeToClose: true,
            componentProps: {
                idAnuncio: anuncio.id,
                tituloAnuncio: anuncio.titulo
            }
        });
        
        return await modal.present();
    }

}
