import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { API } from 'src/providers/api';
import { LocalStorageService }      from 'src/providers/localStorage.service';
import { UpdateService } from 'src/services/update.service';
import { USER_KEY }                 from '../../inc.global';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-popover-oferta',
  templateUrl: './popover-oferta.component.html',
  styleUrls: ['./popover-oferta.component.scss'],
})

export class PopoverOfertaComponent implements OnInit {

	anuncio: any = {};
    idUsuario : number =null;
    idOferta : number = null;
    nombreUsuario = "";
    
    DatosUsuario : any = [];

    @Input()Oferta : any = null;

    constructor(private storage:LocalStorageService,
                private router:Router,
                private updater:UpdateService,
                private api:API,
                private alertCtrl:AlertController,
                private translate:TranslateService
                ) {
                    this.storage.get(USER_KEY).then(
                        (datosUsuario) => {
                            this.DatosUsuario = datosUsuario;
                        }
                    );
                }

    ngOnInit() {}

    inscritos(ev, idOferta) {
        
        const navigationExtras: NavigationExtras = {
            state: {
                idOferta: idOferta,
                prevPage: this.router.url 
            }
        };
		this.router.navigate(['tabs/employ/applications'], navigationExtras);
    }

    ofertaEditar(Oferta) {
        const navigationExtras: NavigationExtras = {
            state: {
                Recurso: Oferta,
                prevPage: this.router.url 
            }
        };
		this.router.navigate(['tabs/employ/job/'], navigationExtras);
    }

    async confirmaBorrado(idOferta) {		
        await this.api.ofertaDelete(idOferta);
        this.updater.ofertaActualizada();
        this.router.navigateByUrl('tabs/employ/my-job-vacancies');            
	}
    
    async ofertaEliminar(idOferta) {
        const alert = await this.alertCtrl.create({
          message: this.translate.instant('PREGUNTA_ELIMINAR_OFERTA'),
          buttons: [
            {
              text: this.translate.instant('BTN_CANCELAR'),
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: (blah) => {
                
              }
            }, {
              text: this.translate.instant('BTN_ELIMINAR'),
              id: 'confirm-button',
              handler: () => {
                    this.confirmaBorrado(idOferta);
              }
            }
          ]
        });
    
        await alert.present();
    }

    
}
