import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UpdateService {

	private postSubject 				= new Subject<any>();
	private comentarioSubject 			= new Subject<any>();
	private mensajeSubject 				= new Subject<any>();
	private mensajesPendientesSubject 	= new Subject<any>();
	private loginSubject				= new Subject<any>();
	private likePostSubject				= new Subject<any>();
	private recomendadoPostSubject		= new Subject<any>();
	private estadoBloqueoUsuarioSubject = new Subject<any>();
	private paginaCambiadaSubject       = new Subject<any>();
    private followSubject               = new Subject<any>();
    private anuncioSubject              = new Subject<any>();
    private cvActualizadoSubject        = new Subject<any>();
    private perfilActualizadoSubject    = new Subject<any>();
    private empresaSubject              = new Subject<any>();
    private ofertaSubject               = new Subject<any>();
    private buscandoVetsSubject         = new Subject<any>();
    private alertaSubject               = new Subject<any>();
    private VimeoUploadProgressSubject  = new Subject<any>();
    private NuevoComentarioSubject           = new Subject<any>();

  	constructor() { }

    NuevoComentario() {
        this.NuevoComentarioSubject.next();
    }

    getNuevoComentario() {
        return this.NuevoComentarioSubject;
    }

    VimeoUploadProgress(progreso){
        this.VimeoUploadProgressSubject.next(progreso);
    }

    getVimeoUploadProgress() {
        return this.VimeoUploadProgressSubject;
    }

    alertaNueva() {
        this.alertaSubject.next();
    }

    getAlertaNueva() {
        return this.alertaSubject;
    }

    ofertaActualizada() {
        this.ofertaSubject.next();
    }

    getOfertaActualizada() {
        return this.ofertaSubject;
    }

    empresaActualizada(datos=null) {
        this.empresaSubject.next(datos);
    }

    getEmpresaActualizada() {
        return this.empresaSubject;
    }

    cvActualizado(bloque) {
        this.cvActualizadoSubject.next(bloque);
    }

    getCV() {
        return this.cvActualizadoSubject;
    }

    getPerfilActualizado() : Subject<any> {
        return this.perfilActualizadoSubject;
    }
    
    perfilActualizado() {
        this.perfilActualizadoSubject.next();
    }

    nuevoAnuncio() {
        
        this.anuncioSubject.next();
    }

    getNuevoAnuncio() {
        return this.anuncioSubject;
    }

    followUsuario(){
        this.followSubject.next();
    }
	
    getFollowUsuario(): Subject<any> {
        return this.followSubject;
    }

	estadoBloqueoUsuario(data:any) {
		this.estadoBloqueoUsuarioSubject.next(data);
	}

	getEstadoBloqueoUsuario(): Subject<any> {
		return this.estadoBloqueoUsuarioSubject;
	}

	// login
	loginRealizado(data:any) {
		this.loginSubject.next(data);
	}

	getLoginObservable(): Subject<any> {
		return this.loginSubject;
	}

	// post
	nuevoPostPublicado(data: any) {
		this.postSubject.next(data);
	}

	getPostObservable(): Subject<any> {
		return this.postSubject;
	}

	likePost(data:any) {
		this.likePostSubject.next(data);
	}

	getLikePostObservable(): Subject<any> {
		return this.likePostSubject;
	}

	recomendadoPost(data:any) {
		this.recomendadoPostSubject.next(data);
	}

	getRecomendadoPostObservable(): Subject<any> {
		return this.recomendadoPostSubject;
	}

	// comentario
	nuevoComentarioPublicado(data: any) {
		this.comentarioSubject.next(data);
	}
	
	getComentarioObservable(): Subject<any> {
		return this.comentarioSubject;
	}

	// mensaje

	nuevoMensajePublicado(data: any) {
		this.mensajeSubject.next(data);
	}

	getMensajeObservable(): Subject<any> {
		return this.mensajeSubject;
	}

	// mensajes pendientes

	mensajesPendientes(data:any) {
		this.mensajesPendientesSubject.next(data);
	}

	getMensajesPendientesObservable(): Subject<any> {
		return this.mensajesPendientesSubject;
	}


    // click y entramos en una nueva página 
    paginaCambiada(data:any) {
        this.paginaCambiadaSubject.next(data);
    }

    getPaginaCambiadaObservable(): Subject<any> {
        return this.paginaCambiadaSubject;
    }

    buscandoVets(data:any) {
        this.buscandoVetsSubject.next(data);
    }

    getbuscandoVetsObservable(): Subject<any> {
        return this.buscandoVetsSubject;
    }
}