import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { API } from 'src/providers/api';
import { LocalStorageService } from 'src/providers/localStorage.service';
import { UpdateService } from 'src/services/update.service';

import { BIG_THUMB_SIZE, AMAZONS3_URL_RESIZE, USER_KEY }             from '../../inc.global';

@Component({
  selector: 'app-bloque-seguir',
  templateUrl: './bloque-seguir.component.html',
  styleUrls: ['./bloque-seguir.component.scss'],
})
export class BloqueSeguirComponent implements OnInit {

    SugeridosTeSiguen       : any = [];
	SugeridosLocalidad      : any = [];
    SugeridosIntereses      : any = [];
    SugeridosUniversidad    : any = [];
    SugeridosEspecies       : any = [];
    
    @Input() hook = 'side'; // side, vets
    Locale : string = null;
    placeHolderRepeticiones : any = [1,2,3,4,5,6,7,8];
    Dimensiones = BIG_THUMB_SIZE;
    AmazonS3Url = AMAZONS3_URL_RESIZE + this.Dimensiones;
    
	constructor(private api:API,
                private updateService:UpdateService,
                private storage:LocalStorageService,
                private router:Router) { 
                    
                }

	ngOnInit() {
        this.updateService.getLoginObservable().subscribe((data)=>{ 
            if (data) this.getSugeridos();
        });		

        this.storage.get(USER_KEY).then(
            (response) => {
                if (response) {
                    this.Locale = response.locale.toLowerCase();
                    this.getSugeridos();
                }
        });
        
        this.updateService.getbuscandoVetsObservable().subscribe((data)=>{
            if (this.hook=='vets') this.getSugeridos();
        });
    }

	async getSugeridos() {
        this.SugeridosTeSiguen      = [];
        this.SugeridosLocalidad     = [];
        this.SugeridosIntereses     = [];
        this.SugeridosUniversidad   = [];
        this.SugeridosEspecies      = [];
        
        let numResultados = 3; 
        if (this.hook=='side') numResultados = 2;            

        let numResultadosInteres = 10; 
        if (this.hook=='side') numResultadosInteres = 2;            
        
        // juraría que esto no lo hace bien así que deshabilito
        //const seguidores = (await this.api.getSugeridos(1, "seguidores",     numResultados)).data; if (seguidores && seguidores.length>0) { seguidores.forEach(element=> { this.SugeridosTeSiguen.push(element); })};
        
        const temp1 = (await this.api.getSugeridos(1, "localidad",      numResultados))?.data; if (temp1 && temp1.length>0)  { temp1.forEach(element=> { this.SugeridosLocalidad.push(element); });}

        if (this.hook!='side') {        
            const temp2 = (await this.api.getSugeridos(1, "interes",        numResultadosInteres))?.data; if (temp2 && temp2.length>0)  { temp2.forEach(element=> { this.SugeridosIntereses.push(element); });}
            const temp3 = (await this.api.getSugeridos(1, "universidad",    numResultados))?.data; if (temp3 && temp3.length>0)  { temp3.forEach(element=> { this.SugeridosUniversidad.push(element); });}
            const temp4 = (await this.api.getSugeridos(1, "especie",        numResultados))?.data; if (temp4 && temp4.length>0)  { temp4.forEach(element=> { this.SugeridosEspecies.push(element); });}
        }
        
		
	}

    abreVets() {
        const navigationExtras: NavigationExtras = {
            state: {
                prevPage: this.router.url 
            }
        };
        this.router.navigate(['tabs/vets'], navigationExtras);
    }

    abreMuro(usuario) {
		const navigationExtras: NavigationExtras = {
            state: {
                UsuarioId: usuario.id,
                prevPage: this.router.url 
            }
        };

        this.storage.get(USER_KEY).then(
            (response) => {
                this.Locale = response.locale.toLowerCase();
                
                if (response.id != usuario.id) {  
                    this.router.navigate(['wall/'], navigationExtras);                    
                } else {
                    this.router.navigate(['my-wall/'], navigationExtras);                    
                }
            }
        );	
	}

    async follow(ev, usuario) {
        ev.stopPropagation();
        await this.api.seguir(usuario.id);
        usuario.LeSigues = true;
    }

    async unfollow(ev, usuario) {
        ev.stopPropagation();
        await this.api.noSeguir(usuario.id);
        usuario.LeSigues = false;
    }

    rechazarSugerido(ev, sugerido) {
        ev.stopPropagation();
		this.api.rechazarSugerido(sugerido).then(
			() => {
				let index = this["Sugeridos"].indexOf(sugerido);
				if(index > -1){ this["Sugeridos"].splice(index, 1); }
			}
		);
	}

    doRefresh(event) {
		setTimeout(() => {
            event.target.complete();
			this.getSugeridos();
		}, 500);
	}
}
