import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { API } from 'src/providers/api';

@Component({
  selector: 'app-respuestas',
  templateUrl: './respuestas.page.html',
  styleUrls: ['./respuestas.page.scss'],
})

export class RespuestasPage implements OnInit {

	@Input()idOferta	: number = null;
	@Input()idUsuario	: number = null;
	@Input()nombreUsuario	= "";

	Respuestas : any = [];

	constructor(private api:API,
				private modalCtrl:ModalController) {
		
	}

	ngOnInit() {
		if (this.idOferta && this.idUsuario) {
			this.cargaRespuestas();
		}
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	async cargaRespuestas() {
		const datos = await this.api.getRespuestasOferta(this.idOferta, this.idUsuario);
		if (datos) {
			this.Respuestas = datos;
		}
	}

}
