<ion-content>
    <ion-list>
        <ion-item ><ion-label class="ion-text-wrap">{{'BTN_ENVIAR'|translate}}</ion-label></ion-item>
        <ion-item button detail="false" (click)="copiarEnlace()"><ion-icon name="link-outline" slot="start"></ion-icon><ion-label>{{'COPIAR_ENLACE'|translate}}</ion-label></ion-item>
        <ion-item lines="none" button detail="false" (click)="mostrarCampoEmail=!mostrarCampoEmail"><ion-icon name="mail-outline" slot="start" ></ion-icon><ion-label>{{'ENVIAR_POR_EMAIL'|translate}}</ion-label></ion-item>
        <ion-grid *ngIf="mostrarCampoEmail">
            <ion-row>
                <ion-col size="9"><div class="bgBorder"><ion-input [(ngModel)]="emailCompartir" ngDefaultControl placeholder="{{'EMAIL_DESTINATARIO'|translate}}" ></ion-input></div></ion-col>
                <ion-col size="2"><ion-button fill="clear" size="small" (click)="enviarPorMail()"><ion-icon slot="icon-only" name="arrow-redo-outline"></ion-icon></ion-button></ion-col>
            </ion-row>
            <ion-row *ngIf="ErrorEmailCompartir"><ion-col><ion-text color="danger">{{ErrorEmailCompartir}}</ion-text></ion-col></ion-row>
        </ion-grid>
    </ion-list>
</ion-content>