import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { AlertController, PopoverController } from '@ionic/angular';
import { API } from 'src/providers/api';

import { APP_URL }             from '../../inc.global';
import { MessagePresenter } from 'src/providers/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popover-compartir-post',
  templateUrl: './popover-compartir-post.component.html',
  styleUrls: ['./popover-compartir-post.component.scss'],
})

export class PopoverCompartirPostComponent implements OnInit {

	@Input() Post: any;
    AppUrl : string = APP_URL;
    mostrarCampoEmail : boolean = false;
    emailCompartir : string = null;
    ErrorEmailCompartir : string = null;

	constructor(private router:Router,
				private api:API,
				private popoverController:PopoverController,
				private alertCtrl: AlertController,
                private messagePresenter: MessagePresenter,
                private translate:TranslateService
				) { }

	ngOnInit() {}

	editarPost(post) {
		this.popoverController.dismiss(null,'editando');		
		const navigationExtras: NavigationExtras = {
            state: {
                PostId: post.PostId,
                prevPage: this.router.url
            }
        };
		this.router.navigate(['post-edit'], navigationExtras);
	}

	async borrarPost(post) {
		
		this.api.borraPost(post.PostId).then(
			() => {	
					this.popoverController.dismiss(null,'borrado');		
					this.presentAlertPublicacionEliminada();
				}
		);
	}

	async presentAlertPublicacionEliminada() {
        const Mensaje = this.translate.instant('GENERAL_PUBLICACION_ELIMINADA');
		
		const alert = await this.alertCtrl.create({
		  cssClass: 'my-custom-class',
		  message: Mensaje,
		  buttons: ['OK']
		});
	
		await alert.present();	
	}

	async cancelar() {
		await this.popoverController.dismiss(null,'cancelar');
	}

    async copiarEnlace(post) {
        
        if (navigator.clipboard) {
            try {
                const url = this.AppUrl + "post/" + post.hash_id;
                await navigator.clipboard.writeText(url);
                this.messagePresenter.presentToast(this.translate.instant('ENLACE_COPIADO'), 3000);
                this.cancelar();
            } catch (err) {}
          }
    }

    async enviarPorMail(post) {
        
        if (!this.validateEmail(this.emailCompartir)) {
            this.ErrorEmailCompartir = this.translate.instant('EMAIL_NO_VALIDO');
            return;
        }

        let ImgAnexo = null;
        if (post.AnexosImg && post.AnexosImg.length>0) {
            ImgAnexo = post.AnexosImg[0]["url"];
        }

        try {
            this.cancelar();
            await this.api.enviarPostMail(post, this.emailCompartir, post.PostDescripcion, ImgAnexo);
            this.ErrorEmailCompartir = null;
            this.messagePresenter.presentToast(this.translate.instant('PUBLICACION_COMPARTIDA'), 3000);
            
        } catch (err) {
            this.ErrorEmailCompartir = "Error";
        }
        

        return;
        //this.popoverController.dismiss();
    }

    validateEmail(email) {
        return String(email)
          .toLowerCase()
          .match(/^\S+@\S+\.\S+$/);
    };
}